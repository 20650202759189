<div class="switchContainer">
    <div class="firstPick">
        <div>
            <input type="checkbox" id="1" name="1" value="Для путей лесной, торфяной промышленности и шахт">
            <label for="1">Для путей лесной, торфяной промышленности и шахт</label>
        </div>
        <div>
            <input type="checkbox" id="2" name="2" value="Для магистральных путей">
            <label for="2">Для магистральных путей</label>
        </div>
        <div>
            <input type="checkbox" id="3" name="3" value="Для путей промышленных предприятий и портов">
            <label for="3">Для путей промышленных предприятий и портов</label>
        </div>
        <div>
            <input type="checkbox" id="4" name="4" value="Для приёмо-отправочных, сортировочных, станционных путей">
            <label for="4">Для приёмо-отправочных, сортировочных, станционных путей</label>
        </div>
        <div>
            <input type="checkbox" id="5" name="5" value="Для линий метрополитена">
            <label for="5">Для линий метрополитена</label>
        </div>
        <div>
            <input type="checkbox" id="6" name="6" value="Для трамвайных путей">
            <label for="6">Для трамвайных путей</label>
        </div>
        <div>
            <input type="checkbox" id="7" name="7" value="Для РЖД">
            <label for="7">Для РЖД</label>
        </div>
    </div>
    <div class="secondPick">
        <h3>ВИД БРУСА</h3>
        <div class="picks picksbruce">
            <div>
                <input type="checkbox" id="bruce1" name="1" value="Деревянный брус">
                <label for="bruce1">Деревянный брус</label>
            </div>
            <div>
                <input type="checkbox" id="bruce2" name="2" value="Железобетонный брус">
                <label for="bruce2">Железобетонный брус</label>
            </div>
        </div>
        <h3>ШИРИНА КОЛЕИ</h3>
        <div class="picks pickskol">
            <div>
                <input type="checkbox" id="kol1" name="1" value="750">
                <label for="kol1">750 мм</label>
            </div>
            <div>
                <input type="checkbox" id="kol2" name="1" value="900">
                <label for="kol2">900 мм</label>
            </div>
            <div>
                <input type="checkbox" id="kol3" name="1" value="1067">
                <label for="kol3">1067 мм</label>
            </div>
            <div>
                <input type="checkbox" id="kol4" name="1" value="1072">
                <label for="kol4">1072 мм</label>
            </div>
            <div>
                <input type="checkbox" id="kol6" name="1" value="1520">
                <label for="kol6">1520 мм</label>
            </div>
            <div>
                <input type="checkbox" id="kol7" name="1" value="1524">
                <label for="kol7">1524 мм</label>
            </div>
            <div>
                <input type="checkbox" id="kol8" name="1" value="1530">
                <label for="kol8">1530 мм</label>
            </div>
            <div>
                <input type="checkbox" id="kol9" name="1" value="1535">
                <label for="kol9">1535 мм</label>
            </div>
        </div>
    </div>
    <div class="thirdPick">
        <h3>ТИП РЕЛЬСА</h3>
        <div class="picks picksrels">
            <div>
                <input type="checkbox" id="rel1" name="1" value="Р33">
                <label for="rel1">P33</label>
            </div>
            <div>
                <input type="checkbox" id="rel2" name="1" value="Р50">
                <label for="rel2">P50</label>
            </div>
            <div>
                <input type="checkbox" id="rel3" name="1" value="Р65">
                <label for="rel3">P65</label>
            </div>
            <div>
                <input type="checkbox" id="rel4" name="1" value="Т62">
                <label for="rel4">T62</label>
            </div>
            
        </div>
        <h3>МАРКА КРЕСТОВИНЫ</h3>
        <div class="picks pickskrest">
            <div>
                <input type="checkbox" id="crossmark1" name="1" value="1/11">
                <label for="crossmark1">1/11</label>
            </div>
            <div>
                <input type="checkbox" id="crossmark2" name="1" value="1/18">
                <label for="crossmark2">1/18</label>
            </div>
            <div>
                <input type="checkbox" id="crossmark3" name="1" value="1/22">
                <label for="crossmark3">1/22</label>
            </div>
            <div>
                <input type="checkbox" id="crossmark4" name="1" value="1/4">
                <label for="crossmark4">1/4</label>
            </div>
            <div>
                <input type="checkbox" id="crossmark5" name="1" value="1/5">
                <label for="crossmark5">1/5</label>
            </div>
            <div>
                <input type="checkbox" id="crossmark6" name="1" value="1/6">
                <label for="crossmark6">1/6</label>
            </div>
            <div>
                <input type="checkbox" id="crossmark7" name="1" value="1/7">
                <label for="crossmark7">1/7</label>
            </div>
            <div>
                <input type="checkbox" id="crossmark8" name="1" value="1/9">
                <label for="crossmark8">1/9</label>
            </div>
            <div>
                <input type="checkbox" id="crossmark10" name="1" value="2/6">
                <label for="crossmark10">2/6</label>
            </div>
            <div>
                <input type="checkbox" id="crossmark11" name="1" value="2/9">
                <label for="crossmark11">2/9</label>
            </div>
        </div>
    </div>
</div>
