<h1 class="title">Каталог стрелочной продукции ООО "ТД НСЗ"</h1>
<div class="mainsection">
    <h2>Более 300 наименований продукции<br /> для успешной работы вашего предприятия</h2>
    <div class="catalogueSwitch">
        <a routerLink="arrow" ><div class="catSwitch" routerLinkActive="activeCatSwitch" value="Стрелочный перевод ">Стрелочные переводы</div></a>
        <a routerLink="cross" ><div class="catSwitch" routerLinkActive="activeCatSwitch" value="Крестовины">Крестовины отдельные</div></a>
        <a routerLink="rem" ><div class="catSwitch" routerLinkActive="activeCatSwitch" value="Ремкомплекты">Ремонтные комплекты</div></a>
        <a routerLink="other" ><div class="catSwitch" routerLinkActive="activeCatSwitch" value="Прочая">Прочая продукция</div></a>
    </div>
    <router-outlet></router-outlet>
    <button class="mainbutton catalogueButton" (click)="onGetResults()">Показать результат</button>
    <div class="results">

    </div>
</div>