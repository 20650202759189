import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Routes, RouterModule } from '@angular/router';

// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopnavComponent } from './topnav/topnav.component';
import { BottomComponent } from './bottom/bottom.component';
import { MainscreenComponent } from './mainscreen/mainscreen.component';
import { AboutComponent } from './about/about.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ConfpolicyComponent } from './confpolicy/confpolicy.component';
import { ArrowComponent } from './catalogue/arrow/arrow.component';
import { CrossComponent } from './catalogue/cross/cross.component';
import { RemComponent } from './catalogue/rem/rem.component';
import { OtherComponent } from './catalogue/other/other.component';
import { MobiletopnavComponent } from './mobiletopnav/mobiletopnav.component';

const appRouts: Routes = [
  { path: '', component: MainscreenComponent },
  { path: 'assets', component: MainscreenComponent },
  { path: 'assets/', component: MainscreenComponent },
  { path: 'assets/img', component: MainscreenComponent },
  { path: 'assets/img/', component: MainscreenComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'catalogue', component: CatalogueComponent,
  children: [
    {path: 'arrow', component: ArrowComponent},
    {path: 'cross', component: CrossComponent},
    {path: 'rem', component: RemComponent},
    {path: 'other', component: OtherComponent},
  ]
 },
  { path: 'confpolicy', component: ConfpolicyComponent }
]

@NgModule({
  declarations: [
    AppComponent,
    TopnavComponent,
    BottomComponent,
    MainscreenComponent,
    AboutComponent,
    CatalogueComponent,
    ContactsComponent,
    ConfpolicyComponent,
    ArrowComponent,
    CrossComponent,
    RemComponent,
    OtherComponent,
    MobiletopnavComponent
  ],
  imports: [
    BrowserModule,    
    RouterModule.forRoot(appRouts),
    FormsModule
    // AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
