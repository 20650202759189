import { Component, Input, OnInit } from '@angular/core';
import { CheckboxControlValueAccessor, CheckboxRequiredValidator } from '@angular/forms';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.sass']
})
export class CatalogueComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
  onGetResults(){
    let activeCatSwitch = document.querySelector('.activeCatSwitch')
    let activeCatSwitchValue = activeCatSwitch.getAttribute('value')
    let finalList=[]
    
    let firstPick = document.querySelector('.firstPick')
    let resultTable = document.querySelector('.results')
    
    let firstPickInputs:any = firstPick.querySelectorAll('input[type=checkbox]')
    let Prods = [

      {
          "data": 
      [
      {
          "id": "1",
          "img": "1",
          "name": "Стрелочный перевод  проект 2832.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/22",
          "name_cross": "2832.03.000\/-01",
          "text": "2832.01.000,-01|2832.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "2",
          "img": "22",
          "name": "Стрелочный перевод  проект 1323.00.000-10\/11",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "1323.02.010",
          "text": "2451.05.010,-01|2451.05.020,-01",
          "rzd": "1"
      },
      {
          "id": "3",
          "img": "23",
          "name": "Стрелочный перевод  проект 2451.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2451.04.000\/-01",
          "text": "2451.05.010,-01|2451.05.020,-01",
          "rzd": "1"
      },
      {
          "id": "4",
          "img": "2",
          "name": "Стрелочный перевод  проект 2870.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2870.03.000\/-01",
          "text": "2870.01.000,-01|2870.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "5",
          "img": "2",
          "name": "Стрелочный перевод  проект 2870.00.000-00 (SKL)",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2870.03.000\/-01",
          "text": "2870.01.000,-01|2870.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "6",
          "img": "2",
          "name": "Стрелочный перевод  проект 2750.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "7",
          "img": "2",
          "name": "Стрелочный перевод  проект 2750.00.000 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "8",
          "img": "4",
          "name": "Стрелочный перевод  проект 2726.00.000 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.03.000\/-01",
          "text": "2726.01.000,-01|2726.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "9",
          "img": "4",
          "name": "Стрелочный перевод  проект 2726.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.03.000\/-01",
          "text": "2726.01.000,-01|2726.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "10",
          "img": "17",
          "name": "Стрелочный перевод  проект 2768.00.000 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01|2768.03.000",
          "text": "2768.01.000,-01|2768.02.000,-01|2768.01.000,-01 РК2|2768.02.000,-01 РК2|2768.01.000,-10,-11|2768.02.000,-10,-11",
          "rzd": "1"
      },
      {
          "id": "11",
          "img": "17",
          "name": "Стрелочный перевод  проект 2768.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01|2768.03.000",
          "text": "2768.01.000,-01|2768.02.000,-01|2768.01.000,-01 РК2|2768.02.000,-01 РК2|2768.01.000,-10,-11|2768.02.000,-10,-11",
          "rzd": "1"
      },
      {
          "id": "12",
          "img": "0",
          "name": "Стрелочный перевод  проект 2285.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2768.03.000",
          "text": "2285.01.010,-01|2285.01.020,-01",
          "rzd": "1"
      },
      {
          "id": "13",
          "img": "19",
          "name": "Стрелочный перевод  проект 2844.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1072",
          "track": "1\/11",
          "name_cross": "СП408-01|2844.03.000 (Для Сахалина)",
          "text": "2844.01.000,-01|2844.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "14",
          "img": "14",
          "name": "Стрелочный перевод  проект 2878.00.000 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2878.03.000\/-01",
          "text": "2878.01.000,-01|2878.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "15",
          "img": "14",
          "name": "Стрелочный перевод  проект 2878.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2878.03.000\/-01",
          "text": "2878.01.000,-01|2878.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "16",
          "img": "15",
          "name": "Стрелочный перевод  проект 2901.00.000 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2901.03.000\/-01",
          "text": "2901.01.000,-01|2901.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "17",
          "img": "15",
          "name": "Стрелочный перевод  проект 2901.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2901.03.000\/-01",
          "text": "2901.01.000,-01|2901.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "18",
          "img": "16",
          "name": "Стрелочный перевод  проект 2925.00.000 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2925.03.000\/-01",
          "text": "2925.01.000,-01|2925.02.000,-01|2925.01.000,-01 РК2|2925.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "19",
          "img": "16",
          "name": "Стрелочный перевод  проект 2925.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2925.03.000\/-01",
          "text": "2925.01.000,-01|2925.02.000,-01|2925.01.000,-01 РК2|2925.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "20",
          "img": "5",
          "name": "Стрелочный перевод  проект 2956.00.000-04\/05 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2956.03.000\/-01",
          "text": "2956.01.000\/-01|2956.02.000\/-01",
          "rzd": "1"
      },
      {
          "id": "21",
          "img": "0",
          "name": "Стрелочный перевод  проект 2433.00.000-04\/05",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408,-01",
          "text": "2433.03.010,-01|2433.03.020,-01|2433.03.010,-01 РК2|2433.03.020,-01 РК2|СП524-06\/-07|СП525-06\/-07|СП524-10,-11|СП525-10,-11",
          "rzd": "1"
      },
      {
          "id": "22",
          "img": "0",
          "name": "Стрелочный перевод  проект 2433.00.000-56\/57",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408",
          "text": "СП524-12\/-13|СП525-12\/-13",
          "rzd": "0"
      },
      {
          "id": "23",
          "img": "0",
          "name": "Стрелочный перевод  проект 1740.00.000-01\/03",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01|1740.02.010",
          "text": "1740.01.010,-01|1740.01.020,-01|1740.01.010,-01 РК2|1740.02.010,-01 РК2|1740.01.010,-10,-11|1740.01.020,-10,-11",
          "rzd": "1"
      },
      {
          "id": "24",
          "img": "0",
          "name": "Стрелочный перевод  проект 1740.00.000-08\/09",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01",
          "text": "1740.01.010,-01|1740.01.020,-01|1740.01.010,-10,-11|1740.01.020,-10,-12|2768.03.000",
          "rzd": "1"
      },
      {
          "id": "25",
          "img": "0",
          "name": "Стрелочный перевод  проект 2193.00.000-06\/07",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408,-01",
          "text": "2193.04.010,-01|2193.04.020,-01|2193.04.010,-01 РК2|2193.04.020,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "26",
          "img": "0",
          "name": "Стрелочный перевод  проект 2688.00.000-02\/03",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408,-01",
          "text": "2688.01.010,-01|2688.01.020,-01|2688.01.010,-01 РК2|2688.01.020,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "27",
          "img": "0",
          "name": "Стрелочный перевод  проект 2450.00.000 НПК",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2450.03.000",
          "text": "2450.01.010|2450.01.020",
          "rzd": "1"
      },
      {
          "id": "28",
          "img": "39",
          "name": "Стрелочный перевод  проект 1848.00.000-10",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408,-01",
          "text": "1848.01.010-10,-12|1848.01.010-10,-12 РК2",
          "rzd": "1"
      },
      {
          "id": "29",
          "img": "26",
          "name": "Стрелочный перевод  проект 2717-10\/11",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2717.02.010",
          "text": "2717.01.010,-01|2717.01.020,-01|2717.01.010,-01 РК2|2717.01.020,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "30",
          "img": "27",
          "name": "Стрелочный перевод  проект 2764.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01",
          "text": "2764.01.000,-01|2764.02.000,-01|2764.01.000,-01 РК2|2764.02.000,-01 РК2|2764.01.000,-10,-11|2764.02.000,-10,-11",
          "rzd": "1"
      },
      {
          "id": "31",
          "img": "29",
          "name": "Стрелочный перевод  проект 2771.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2764.03.000",
          "text": "2771.01.000,-01|2771.02.000,-01|2771.01.000,-01 РК2|2771.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "32",
          "img": "31",
          "name": "Стрелочный перевод  проект 2773.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01|2764.03.000",
          "text": "2773.01.000,-01|2773.02.000,-01|2773.01.000,-01 РК2|2773.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "33",
          "img": "34",
          "name": "Стрелочный перевод  проект 2802.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2802.03.000\/-01",
          "text": "2802.01.000,-01|2802.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "34",
          "img": "35",
          "name": "Стрелочный перевод  проект 2851.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2851.03.000,-01",
          "text": "2851.01.000,-01|2851.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "35",
          "img": "33",
          "name": "Стрелочный перевод  проект 2759.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2759.03.000",
          "text": "2759.01.000,-01|2759.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "36",
          "img": "0",
          "name": "Стрелочный перевод  проект 2796.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2796.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "37",
          "img": "18",
          "name": "Стрелочный перевод  проект 2769.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418-01|2769.03.000\/-01",
          "text": "2768.01.000,-01|2768.02.000,-01|2768.01.000,-01 РК2|2768.02.000,-01 РК2|2768.01.000,-10,-11|2768.02.000,-10,-11",
          "rzd": "1"
      },
      {
          "id": "38",
          "img": "20",
          "name": "Стрелочный перевод  проект 2845.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1067",
          "track": "1\/9",
          "name_cross": "СП418-01|2845.03.000 (Для Сахалина)",
          "text": "2844.01.000,-01|2844.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "39",
          "img": "0",
          "name": "Стрелочный перевод  проект 2843.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2843.05.000|2843.03.000",
          "text": "2843.01.000,-01|2843.02.000,-01|2843.01.000,-01 РК2|2843.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "40",
          "img": "0",
          "name": "Стрелочный перевод  проект 2843.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1530",
          "track": "1\/9",
          "name_cross": "2843.05.000|2843.03.000",
          "text": "2843.01.000,-01|2843.02.000,-01|2843.01.000,-01 РК2|2843.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "41",
          "img": "0",
          "name": "Стрелочный перевод  проект 2434.00.000-04\/05",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418,-01",
          "text": "2433.03.010,-01 РК2|2433.03.020,-01 РК2|СП524-06\/-07|СП525-06\/-07|СП524-10,-11|СП525-10,-11",
          "rzd": "1"
      },
      {
          "id": "42",
          "img": "0",
          "name": "Стрелочный перевод  проект 2434.00.000-40\/41",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП408",
          "text": "СП524-12\/-13|СП525-12\/-13",
          "rzd": "0"
      },
      {
          "id": "43",
          "img": "0",
          "name": "Стрелочный перевод  проект 2215.00.000-04\/05",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2215.01.010|СП418-01",
          "text": "1740.01.010,-01|1740.01.020,-01|1740.01.010,-01 РК2|1740.02.010,-01 РК2|1740.01.010,-10,-11|1740.01.020,-10,-11",
          "rzd": "1"
      },
      {
          "id": "44",
          "img": "0",
          "name": "Стрелочный перевод  проект 2215.00.000-08\/09",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1740.01.020|2768.03.000",
          "text": "1740.01.010,-01|1740.01.020,-01|1740.01.010,-01 РК2|1740.02.010,-01 РК2|1740.01.010,-10,-11|1740.01.020,-10,-11",
          "rzd": "1"
      },
      {
          "id": "45",
          "img": "0",
          "name": "Стрелочный перевод  проект 2244.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418,-01",
          "text": "2193.04.010,-01|2193.04.020,-01|2193.04.010,-01 РК2|2193.04.020,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "46",
          "img": "28",
          "name": "Стрелочный перевод  проект 2766.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2766.03.000|СП418-01",
          "text": "2764.01.000,-01|2764.02.000,-01|2764.01.000,-01 РК2|2764.02.000,-01 РК2|2764.01.000,-10,-11|2764.02.000,-10,-11",
          "rzd": "1"
      },
      {
          "id": "47",
          "img": "32",
          "name": "Стрелочный перевод  проект 2721.00.000-02\/03",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418-01|2766.03.000",
          "text": "2773.01.000,-01|2773.02.000,-01|2773.01.000,-01 РК2|2773.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "48",
          "img": "30",
          "name": "Стрелочный перевод  проект 2772.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418-01|2766.03.000",
          "text": "2771.01.000,-01|2771.02.000,-01|2771.01.000,-01 РК2|2771.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "49",
          "img": "0",
          "name": "Стрелочный перевод  проект 1580.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418,-01|1580.02.000",
          "text": "1580.01.010,-01|1580.01.020,-01|1580.01.010,-01 РК2|1580.01.020,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "50",
          "img": "0",
          "name": "Стрелочный перевод  проект 2227.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2227.02.000|1580.03.000",
          "text": "2227.01.010,-01|1580.01.020,-01",
          "rzd": "1"
      },
      {
          "id": "51",
          "img": "43",
          "name": "Стрелочный перевод  проект 2869.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2869.05.000|2869.03.000",
          "text": "2869.01.000,-01|2869.02.000,-01|2869.01.000,-01 РК2|2869.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "52",
          "img": "43",
          "name": "Стрелочный перевод  проект 2869.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1530",
          "track": "1\/9",
          "name_cross": "2869.05.000|2869.03.000",
          "text": "2869.01.000,-01|2869.02.000,-01|2869.01.000,-01 РК2|2869.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "53",
          "img": "0",
          "name": "Стрелочный перевод  проект 267А.00.000-02\/03",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-10",
          "text": "267А110.00,-01|267А120.00,-01|267А110.00,-01 РК2|267А120.00,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "54",
          "img": "0",
          "name": "Стрелочный перевод  проект 267А.00.000-04\/05",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-10",
          "text": "267А110.00,-01|267А120.00,-01|267А110.00,-01 РК2|267А120.00,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "55",
          "img": "0",
          "name": "Стрелочный перевод  проект 267А.00.000-06\/07",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-10",
          "text": "267А110.00,-01|267А120.00,-01|267А110.00,-01 РК2|267А120.00,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "56",
          "img": "0",
          "name": "Стрелочный перевод  проект 665121.001\/-01",
          "group": "Стрелочный перевод ",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "ЛПТП.668341.001,-01|ЛПТП.668342.001,-01|ЛПТП.668341.001,-01 РК2|ЛПТП.668342.001,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "57",
          "img": "0",
          "name": "Стрелочный перевод  проект 665121.001\/-02\/03",
          "group": "Стрелочный перевод ",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "ЛПТП.668341.001,-01|ЛПТП.668342.001,-01|ЛПТП.668341.001,-01 РК2|ЛПТП.668342.001,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "58",
          "img": "0",
          "name": "Стрелочный перевод  проект 665121.001\/-04\/05",
          "group": "Стрелочный перевод ",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "ЛПТП.668341.001,-01|ЛПТП.668342.001,-01|ЛПТП.668341.001,-01 РК2|ЛПТП.668342.001,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "59",
          "img": "0",
          "name": "Стрелочный перевод  проект 665121.001\/-06\/07",
          "group": "Стрелочный перевод ",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "ЛПТП.668341.001,-01|ЛПТП.668342.001,-01|ЛПТП.668341.001,-01 РК2|ЛПТП.668342.001,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "60",
          "img": "0",
          "name": "Стрелочный перевод  проект ЛПТП665121.100\/-01",
          "group": "Стрелочный перевод ",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/9",
          "name_cross": "2434.04.010",
          "text": "ЛПТП668.341.100,-01|ЛПТП668.342.100,-02",
          "rzd": "0"
      },
      {
          "id": "61",
          "img": "0",
          "name": "Стрелочный перевод  проект ЛПТП665121.101\/-01",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "ЛПТП.668341.101,-01|ЛПТП.668342.101,-01|ЛПТП.668341.101,-01 РК2|ЛПТП.668342.101,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "62",
          "img": "0",
          "name": "Стрелочный перевод  проект ЛПТП665121.101-02\/-03",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "ЛПТП.668341.101,-01|ЛПТП.668342.101,-01|ЛПТП.668341.101,-01 РК2|ЛПТП.668342.101,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "63",
          "img": "0",
          "name": "Стрелочный перевод  проект 2690.00.000-18\/19",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/7",
          "name_cross": "2690.08.010",
          "text": "2690.06.010,-01|2690.06.020,-01|2690.06.010,-01 РК2|2690.06.020,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "64",
          "img": "0",
          "name": "Стрелочный перевод  проект 2690.00.000-20\/21",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/7",
          "name_cross": "2690.08.010",
          "text": "2690.06.010,-01|2690.06.020,-01|2690.06.010,-01 РК2|2690.06.020,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "65",
          "img": "0",
          "name": "Стрелочный перевод  проект НЛПТП665121.103\/-01",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/7",
          "name_cross": "НЛПТП.668352.103",
          "text": "ЛПТП.668341.103,-01|ЛПТП.668342.103,-01",
          "rzd": "0"
      },
      {
          "id": "66",
          "img": "40",
          "name": "Стрелочный перевод  проект 2307.00.000-02",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "СП648",
          "text": "2307.01.010,-01|2307.01.010,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "67",
          "img": "40",
          "name": "Стрелочный перевод  проект 2307.00.000-20\/21",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "СП648",
          "text": "2307.01.010-08,-09|2307.01.010-08,-09 РК2",
          "rzd": "1"
      },
      {
          "id": "68",
          "img": "21",
          "name": "Стрелочный перевод  проект 2628.00.000-06",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "2628.06.010",
          "text": "2628.01.010,-01|2628.01.010,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "69",
          "img": "36",
          "name": "Стрелочный перевод  проект 2642.00.000-04\/05",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2642.01.010",
          "text": "СП574,-01|СП575,-01|2642.03.010,-01 РК2|2642.03.020,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "70",
          "img": "37",
          "name": "Стрелочный перевод  проект 2643.00.000-04\/05",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1435",
          "track": "1\/9",
          "name_cross": "2643.01.010",
          "text": "СП574,-01|СП575,-01|2642.03.010,-01 РК2|2642.03.020,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "71",
          "img": "37",
          "name": "Стрелочный перевод  проект 2643.00.000-04\/05",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2643.01.010",
          "text": "СП574,-01|СП575,-01|2642.03.010,-01 РК2|2642.03.020,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "72",
          "img": "45",
          "name": "Стрелочный перевод  проект 1623.00.000-03",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП514|1623.02.000|2643.01.010-01",
          "text": "1623.01.010-04\/-05|1623.01.020-04\/-05|1623.01.010-04\/-05 РК2|1623.01.020-04\/-05 РК2",
          "rzd": "1"
      },
      {
          "id": "73",
          "img": "45",
          "name": "Стрелочный перевод  проект 1623.00.000-03",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1535",
          "track": "1\/9",
          "name_cross": "СП514|1623.02.000|2643.01.010-01",
          "text": "1623.01.010-04\/-05|1623.01.020-04\/-05|1623.01.010-04\/-05 РК2|1623.01.020-04\/-05 РК2",
          "rzd": "1"
      },
      {
          "id": "74",
          "img": "41",
          "name": "Стрелочный перевод  проект 1581.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "СП458",
          "text": "1581.01.010-02,-03|1581.01.010,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "75",
          "img": "42",
          "name": "Стрелочный перевод  проект 2212.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "СП458",
          "text": "2212.01.010,-01",
          "rzd": "1"
      },
      {
          "id": "76",
          "img": "38",
          "name": "Стрелочный перевод  проект 1909.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для линий метрополитена",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1524",
          "track": "1\/5",
          "name_cross": "1909.02.010",
          "text": "1909.01.010,-01…-07",
          "rzd": "0"
      },
      {
          "id": "77",
          "img": "0",
          "name": "Стрелочный перевод  проект 2673.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "750",
          "track": "1\/5",
          "name_cross": "2673.02.010",
          "text": "2673.01.010,-01|2673.01.010-02,-03",
          "rzd": "0"
      },
      {
          "id": "78",
          "img": "0",
          "name": "Стрелочный перевод  проект ПОНШ933.00.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р33",
          "cross": "900",
          "track": "1\/5",
          "name_cross": "ПОНШ|933.60.00.000",
          "text": "ПОНШ933.20.00-001,-01|ПОНШ933.20.00.002,-01",
          "rzd": "0"
      },
      {
          "id": "79",
          "img": "0",
          "name": "Стрелочный перевод  проект ПШОН 733.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р33",
          "cross": "750",
          "track": "1\/5",
          "name_cross": "ПОНШ|933.60.00.000",
          "text": "ПОНШ933.20.00-001,-01|ПОНШ933.20.00.002,-01",
          "rzd": "0"
      },
      {
          "id": "80",
          "img": "0",
          "name": "Стрелочный перевод  проект ПШОУ 20.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р33",
          "cross": "900",
          "track": "1\/4",
          "name_cross": "ПШОУ|20.07.000-01",
          "text": "ПОНШ933.20.00-001,-01|ПОНШ933.20.00.002,-01",
          "rzd": "0"
      },
      {
          "id": "81",
          "img": "0",
          "name": "Стрелка трамвайная проект Н-С30-00.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Т62",
          "cross": "1524",
          "track": "",
          "name_cross": "",
          "text": "Н-С30-01.01.000|Н-С30-02.01.000",
          "rzd": "0"
      },
      {
          "id": "82",
          "img": "0",
          "name": "Стрелка трамвайная проект Н-С50-00.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Т62",
          "cross": "1524",
          "track": "",
          "name_cross": "",
          "text": "Н-С50-01.01.000|Н-С50-02.01.001",
          "rzd": "0"
      },
      {
          "id": "83",
          "img": "3",
          "name": "Съезд одиночный проект 2833.00.000-00\/01",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/22",
          "name_cross": "2832.03.000\/-01",
          "text": "2832.01.000,-01|2832.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "84",
          "img": "9",
          "name": "Съезд одиночный проект 2728.00.000 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.03.000\/-01",
          "text": "2726.01.000,-01|2726.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "85",
          "img": "0",
          "name": "Съезд одиночный проект 2728.00.000 ",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.03.000\/-01",
          "text": "2726.01.000,-01|2726.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "86",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-00\/01 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "87",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-00\/01 ",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "88",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-02\/03 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "89",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-02\/03",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "90",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-04\/05 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "91",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-04\/05 ",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "92",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-06\/07 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "93",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-06\/07 ",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "94",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-08\/09 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "95",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-08\/09",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "96",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-10\/11 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "97",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-10\/11",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "98",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-12\/13 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "99",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-12\/13",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "100",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-14\/15 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "101",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-14\/15",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "102",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-16\/17 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "103",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-16\/17 ",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "104",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-18\/19 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "105",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-18\/19",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "106",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-20\/21 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "107",
          "img": "11",
          "name": "Съезд одиночный проект 2799.00.000-20\/21",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "rzd": "1"
      },
      {
          "id": "108",
          "img": "8",
          "name": "Съезд одиночный проект 2968.00.000-06\/07 SKL",
          "group": "Стрелочный перевод ",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2956.03.000\/-01",
          "text": "2956.01.000,-01|2956.02.000,-01",
          "rzd": "1"
      },
      {
          "id": "109",
          "img": "0",
          "name": "Съезд перекрестный проект 2216.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для линий метрополитена",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "СП566-01|СП568-01",
          "text": "2433.03.010,-01 РК2|2433.03.020,-01 РК2|СП524-06\/-07|СП525-06\/-07|СП524-10,-11 РК2|СП525-10,-11 РК2",
          "rzd": "0"
      },
      {
          "id": "110",
          "img": "0",
          "name": "Съезд перекрестный проект 2099.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для линий метрополитена",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "НМ3.02.000|НМ4.01.000|М5.00.000",
          "text": "СП574,-01|СП575,-01|2642.03.010,-01 РК2|2642.03.020,-01 РК2",
          "rzd": "0"
      },
      {
          "id": "111",
          "img": "44",
          "name": "Съезд двойной перекрестный проект 2519.00.000-01",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/6",
          "name_cross": "2519.01.000|2519.02.000|2519.03.000|2521.05.000|СП648",
          "text": "2307.01.010|-01",
          "rzd": "1"
      },
      {
          "id": "112",
          "img": "46",
          "name": "Пересечение глухое проект 1683.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/11",
          "name_cross": "1683.01.000|СП557|СП559",
          "text": "",
          "rzd": "1"
      },
      {
          "id": "113",
          "img": "47",
          "name": "Пересечение глухое проект 1684.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "1684.01.000|СП566|СП568",
          "text": "",
          "rzd": "1"
      },
      {
          "id": "114",
          "img": "49",
          "name": "Пересечение глухое проект 2521.00.000-02",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/6",
          "name_cross": "2521.00.020-01|2521.05.000",
          "text": "",
          "rzd": "1"
      },
      {
          "id": "115",
          "img": "51",
          "name": "Пересечение глухое проект Н2576.000",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "2576.100",
          "text": "",
          "rzd": "1"
      },
      {
          "id": "116",
          "img": "48",
          "name": "Пересечение глухое проект 1624.00.000-02",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2643.01.010-01|1624.02.000-01",
          "text": "",
          "rzd": "1"
      },
      {
          "id": "117",
          "img": "48",
          "name": "Пересечение глухое проект 1624.00.000-02",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1435",
          "track": "1\/9",
          "name_cross": "2643.01.010-01|1624.02.000-01",
          "text": "",
          "rzd": "1"
      },
      {
          "id": "118",
          "img": "0",
          "name": "Пересечение глухое проект 1685.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "2\/11",
          "name_cross": "1685.01.000|СП555|СП551",
          "text": "",
          "rzd": "1"
      },
      {
          "id": "119",
          "img": "50",
          "name": "Пересечение глухое проект 1686.00.000",
          "group": "Стрелочный перевод ",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "1657.000|СП516|СП508",
          "text": "",
          "rzd": "1"
      },
      {
          "id": "120",
          "img": "0",
          "name": "Уравнительный прибор пр. 1262А.00.000-02",
          "group": "Прочая",
          "type": "Уравнительный прибор",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "Для мостов",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "121",
          "img": "0",
          "name": "Уравнительный прибор пр. 1262А.00.000-03",
          "group": "Прочая",
          "type": "Уравнительный прибор",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "Для мостов",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "122",
          "img": "0",
          "name": "Уравнительный прибор пр. 012А.00.000",
          "group": "Прочая",
          "type": "Уравнительный прибор",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "Для мостов",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "123",
          "img": "0",
          "name": "Уравнительный прибор пр. 012А.00.000",
          "group": "Прочая",
          "type": "Уравнительный прибор",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1530",
          "track": "",
          "name_cross": "Для мостов",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "124",
          "img": "0",
          "name": "Башмакосбрасыватели пр. 1703.000\/01",
          "group": "Прочая",
          "type": "Башмакосбрасыватели",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "Сбрасывание башмаков",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "125",
          "img": "0",
          "name": "Башмакосбрасыватели пр. 1704.000\/01",
          "group": "Прочая",
          "type": "Башмакосбрасыватели",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "Сбрасывание башмаков",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "126",
          "img": "0",
          "name": "Стрелка сбрасывающая пр. 2884.00.000-00\/01",
          "group": "Прочая",
          "type": "Стрелка сбрасывающая",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "Предотвраще ние аварийных ситуаций",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "127",
          "img": "0",
          "name": "Стрелка сбрасывающая пр. 2663.000-04\/05",
          "group": "Прочая",
          "type": "Стрелка сбрасывающая",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "Предотвраще ние аварийных ситуаций",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "128",
          "img": "0",
          "name": "Стрелка сбрасывающая пр. 1878-00\/02",
          "group": "Прочая",
          "type": "Стрелка сбрасывающая",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "",
          "name_cross": "Предотвраще ние аварийных ситуаций",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "129",
          "img": "0",
          "name": "Стрелка сбрасывающая пр. 1878-04\/05",
          "group": "Прочая",
          "type": "Стрелка сбрасывающая",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "",
          "name_cross": "Предотвраще ние аварийных ситуаций",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "130",
          "img": "0",
          "name": "Ручной переводной механизм пр. 1709.000",
          "group": "Прочая",
          "type": "Ручной переводной механизм",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Смена направления пути",
          "text": "Р65|Р50",
          "rzd": "0"
      },
      {
          "id": "131",
          "img": "0",
          "name": "Гарнитура пр. 17361-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/11 на ЖББ с замыкателем ВЗК-2",
          "text": "2726.00.000|2728.00.000",
          "rzd": "0"
      },
      {
          "id": "132",
          "img": "0",
          "name": "Гарнитура пр. 17376-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для крестовины с НПК Р65 М1\/11 на ЖББ с замыкателем ВЗК-2",
          "text": "2726.00.000|2728.00.000",
          "rzd": "0"
      },
      {
          "id": "133",
          "img": "0",
          "name": "Гарнитура пр. 17425-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки на ЖББ Т.Р65 М1\/11",
          "text": "2750.00.000|2799.00.000|2796.00.000",
          "rzd": "0"
      },
      {
          "id": "134",
          "img": "0",
          "name": "Гарнитура пр. 17531-00-00-02",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/9, 1\/11 колеи 1067 на ЖББ",
          "text": "2845.00.000",
          "rzd": "0"
      },
      {
          "id": "135",
          "img": "0",
          "name": "Гарнитура пр. 17531-00-00-03",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/9, 1\/11 колеи 1067 на дереве",
          "text": "2844.00.000",
          "rzd": "0"
      },
      {
          "id": "136",
          "img": "0",
          "name": "Гарнитура пр. 17532-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для перекрестнй стрелки Р65 М1\/9",
          "text": "2843.00.000",
          "rzd": "0"
      },
      {
          "id": "137",
          "img": "0",
          "name": "Гарнитура пр. 17549-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/11 радиуса 900 м",
          "text": "2851.00.000",
          "rzd": "0"
      },
      {
          "id": "138",
          "img": "0",
          "name": "Гарнитура пр. 17558-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/18 на ЖББ с вертикальным замыкателем В3-7",
          "text": "2870.00.000",
          "rzd": "0"
      },
      {
          "id": "139",
          "img": "0",
          "name": "Гарнитура пр. 17559-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для крестовины с НПК Р65 М1\/18 на ЖББ с замыкателем В3К-2",
          "text": "2870.00.000",
          "rzd": "0"
      },
      {
          "id": "140",
          "img": "0",
          "name": "Гарнитура пр. 17561-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для перекрестнй стрелки Р65 М1\/9",
          "text": "2869.00.000",
          "rzd": "0"
      },
      {
          "id": "141",
          "img": "0",
          "name": "Гарнитура пр. 17564-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/11 радиуса 600 м на ЖББ",
          "text": "2878.00.000|2925.00.000",
          "rzd": "0"
      },
      {
          "id": "142",
          "img": "0",
          "name": "Гарнитура пр. 17586-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для сбрасывающей стрелки на ЖББ",
          "text": "2884.00.000",
          "rzd": "0"
      },
      {
          "id": "143",
          "img": "0",
          "name": "Гарнитура пр. 17586-00-00Д",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для сбрасывающей стрелки на дереве",
          "text": "2663.00.000",
          "rzd": "0"
      },
      {
          "id": "144",
          "img": "0",
          "name": "Гарнитура пр. 17590-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/11 радиуса 600 м",
          "text": "2802.00.000",
          "rzd": "0"
      },
      {
          "id": "145",
          "img": "0",
          "name": "Гарнитура пр. 17610-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/11 радиуса 900 м на ЖББ",
          "text": "2901.00.000",
          "rzd": "0"
      },
      {
          "id": "146",
          "img": "0",
          "name": "Гарнитура пр. 17792-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/11 на ЖББ с замыкателем ВЗ-7",
          "text": "2956.00.000|2968.00.000",
          "rzd": "0"
      },
      {
          "id": "147",
          "img": "0",
          "name": "Гарнитура пр. 17793-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для стрелки Р65 М1\/11 на ЖББ с замыкателем ВЗ-7",
          "text": "2956.00.000|2968.00.000",
          "rzd": "0"
      },
      {
          "id": "148",
          "img": "0",
          "name": "Гарнитура пр. 17794-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для крестовины Р65 М1\/11 на ЖББ с замыкателем ВЗК-2",
          "text": "2956.00.000|2968.00.000",
          "rzd": "0"
      },
      {
          "id": "149",
          "img": "0",
          "name": "Гарнитура пр. 17795-00-00",
          "group": "Прочая",
          "type": "Гарнитура",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "Для крестовины Р65 М1\/11 на ЖББ с фиксатором положения сердечника",
          "text": "2956.00.000|2968.00.000",
          "rzd": "0"
      },
      {
          "id": "150",
          "img": "0",
          "name": "2832.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/22",
          "name_cross": "2832.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "151",
          "img": "22",
          "name": "1323.00.000-10\/11",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "1323.02.010",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "152",
          "img": "23",
          "name": "2451.00.000",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2451.04.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "153",
          "img": "2",
          "name": "2870.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2870.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "154",
          "img": "2",
          "name": "2870.00.000-00 (SKL)",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2870.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "155",
          "img": "2",
          "name": "2750.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "156",
          "img": "2",
          "name": "2750.00.000 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "157",
          "img": "4",
          "name": "2726.00.000 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "158",
          "img": "4",
          "name": "2726.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "159",
          "img": "17",
          "name": "2768.00.000 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01|2768.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "160",
          "img": "17",
          "name": "2768.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01|2768.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "161",
          "img": "0",
          "name": "2285.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2768.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "162",
          "img": "19",
          "name": "2844.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1072",
          "track": "1\/11",
          "name_cross": "СП408-01|2844.03.000 (Для Сахалина)",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "163",
          "img": "14",
          "name": "2878.00.000 SKL",
          "group": "Крестовины",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2878.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "164",
          "img": "14",
          "name": "2878.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2878.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "165",
          "img": "15",
          "name": "2901.00.000 SKL",
          "group": "Крестовины",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2901.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "166",
          "img": "15",
          "name": "2901.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2901.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "167",
          "img": "16",
          "name": "2925.00.000 SKL",
          "group": "Крестовины",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2925.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "168",
          "img": "16",
          "name": "2925.00.000",
          "group": "Крестовины",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2925.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "169",
          "img": "5",
          "name": "2956.00.000-04\/05 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2956.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "170",
          "img": "0",
          "name": "2433.00.000-04\/05",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "171",
          "img": "0",
          "name": "2433.00.000-56\/57",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "172",
          "img": "0",
          "name": "1740.00.000-01\/03",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01|1740.02.010",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "173",
          "img": "0",
          "name": "1740.00.000-08\/09",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "174",
          "img": "0",
          "name": "2193.00.000-06\/07",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "175",
          "img": "0",
          "name": "2688.00.000-02\/03",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "176",
          "img": "0",
          "name": "2450.00.000 НПК",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2450.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "177",
          "img": "39",
          "name": "1848.00.000-10",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "178",
          "img": "26",
          "name": "2717-10\/11",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2717.02.010",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "179",
          "img": "27",
          "name": "2764.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "180",
          "img": "29",
          "name": "2771.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2764.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "181",
          "img": "31",
          "name": "2773.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП408-01|2764.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "182",
          "img": "34",
          "name": "2802.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2802.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "183",
          "img": "35",
          "name": "2851.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2851.03.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "184",
          "img": "33",
          "name": "2759.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2759.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "185",
          "img": "0",
          "name": "2796.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2796.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "186",
          "img": "18",
          "name": "2769.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418-01|2769.03.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "187",
          "img": "20",
          "name": "2845.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1067",
          "track": "1\/9",
          "name_cross": "СП418-01|2845.03.000 (Для Сахалина)",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "188",
          "img": "0",
          "name": "2843.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2843.05.000|2843.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "189",
          "img": "0",
          "name": "2843.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1530",
          "track": "1\/9",
          "name_cross": "2843.05.000|2843.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "190",
          "img": "0",
          "name": "2434.00.000-04\/05",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "191",
          "img": "0",
          "name": "2434.00.000-40\/41",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП408",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "192",
          "img": "0",
          "name": "2215.00.000-04\/05",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2215.01.010|СП418-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "193",
          "img": "0",
          "name": "2215.00.000-08\/09",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1740.01.020|2768.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "194",
          "img": "0",
          "name": "2244.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "195",
          "img": "28",
          "name": "2766.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2766.03.000|СП418-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "196",
          "img": "32",
          "name": "2721.00.000-02\/03",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418-01|2766.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "197",
          "img": "30",
          "name": "2772.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418-01|2766.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "198",
          "img": "0",
          "name": "1580.00.000",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП418,-01|1580.02.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "199",
          "img": "0",
          "name": "2227.00.000",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2227.02.000|1580.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "200",
          "img": "43",
          "name": "2869.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2869.05.000|2869.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "201",
          "img": "0",
          "name": "2869.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1530",
          "track": "1\/9",
          "name_cross": "2869.05.000|2869.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "202",
          "img": "0",
          "name": "267А.00.000-02\/03",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-10",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "203",
          "img": "0",
          "name": "267А.00.000-04\/05",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-10",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "204",
          "img": "0",
          "name": "267А.00.000-06\/07",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-10",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "205",
          "img": "0",
          "name": "665121.001\/-01",
          "group": "Крестовины",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "206",
          "img": "0",
          "name": "665121.001\/-02\/03",
          "group": "Крестовины",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "207",
          "img": "0",
          "name": "665121.001\/-04\/05",
          "group": "Крестовины",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "208",
          "img": "0",
          "name": "665121.001\/-06\/07",
          "group": "Крестовины",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "209",
          "img": "0",
          "name": "ЛПТП665121.100\/-01",
          "group": "Крестовины",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/9",
          "name_cross": "2434.04.010",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "210",
          "img": "0",
          "name": "ЛПТП665121.101\/-01",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "211",
          "img": "0",
          "name": "ЛПТП665121.101-02\/-03",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1088.00.000-02",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "212",
          "img": "0",
          "name": "2690.00.000-18\/19",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/7",
          "name_cross": "2690.08.010",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "213",
          "img": "0",
          "name": "2690.00.000-20\/21",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/7",
          "name_cross": "2690.08.010",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "214",
          "img": "0",
          "name": "НЛПТП665121.103\/-01",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/7",
          "name_cross": "НЛПТП.668352.103",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "215",
          "img": "40",
          "name": "2307.00.000-02",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "СП648",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "216",
          "img": "0",
          "name": "2307.00.000-20\/21",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "СП648",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "217",
          "img": "21",
          "name": "2628.00.000-06",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "2628.06.010",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "218",
          "img": "36",
          "name": "2642.00.000-04\/05",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2642.01.010",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "219",
          "img": "37",
          "name": "2643.00.000-04\/05",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1435",
          "track": "1\/9",
          "name_cross": "2643.01.010",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "220",
          "img": "37",
          "name": "2643.00.000-04\/05",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2643.01.010",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "221",
          "img": "45",
          "name": "1623.00.000-03",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП514|1623.02.000|2643.01.010-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "222",
          "img": "45",
          "name": "1623.00.000-03",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1535",
          "track": "1\/9",
          "name_cross": "СП514|1623.02.000|2643.01.010-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "223",
          "img": "41",
          "name": "1581.00.000",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "СП458",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "224",
          "img": "42",
          "name": "2212.00.000",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "СП458",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "225",
          "img": "38",
          "name": "1909.00.000",
          "group": "Крестовины",
          "type": "Для линий метрополитена",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1524",
          "track": "1\/5",
          "name_cross": "1909.02.010",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "226",
          "img": "0",
          "name": "2673.00.000",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "750",
          "track": "1\/5",
          "name_cross": "2673.02.010",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "227",
          "img": "0",
          "name": "ПОНШ933.00.00.000",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р33",
          "cross": "900",
          "track": "1\/5",
          "name_cross": "ПОНШ|933.60.00.000",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "228",
          "img": "0",
          "name": "ПШОН 733.00.000",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р33",
          "cross": "750",
          "track": "1\/5",
          "name_cross": "ПОНШ|933.60.00.000",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "229",
          "img": "0",
          "name": "ПШОУ 20.00.000",
          "group": "Крестовины",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р33",
          "cross": "900",
          "track": "1\/4",
          "name_cross": "ПШОУ|20.07.000-01",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "230",
          "img": "3",
          "name": "2833.00.000-00\/01",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/22",
          "name_cross": "2832.03.000\/-01",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "231",
          "img": "9",
          "name": "2728.00.000 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.03.000\/-01",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "232",
          "img": "0",
          "name": "2728.00.000 ",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.03.000\/-01",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "233",
          "img": "11",
          "name": "2799.00.000-00\/01 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "234",
          "img": "11",
          "name": "2799.00.000-00\/01 ",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "235",
          "img": "11",
          "name": "2799.00.000-02\/03 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "236",
          "img": "11",
          "name": "2799.00.000-02\/03",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "237",
          "img": "11",
          "name": "2799.00.000-04\/05 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "238",
          "img": "11",
          "name": "2799.00.000-04\/05 ",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "239",
          "img": "11",
          "name": "2799.00.000-06\/07 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "240",
          "img": "11",
          "name": "2799.00.000-06\/07 ",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "241",
          "img": "11",
          "name": "2799.00.000-08\/09 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "242",
          "img": "11",
          "name": "2799.00.000-08\/09",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "243",
          "img": "11",
          "name": "2799.00.000-10\/11 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "244",
          "img": "11",
          "name": "2799.00.000-10\/11",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "245",
          "img": "11",
          "name": "2799.00.000-12\/13 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "246",
          "img": "11",
          "name": "2799.00.000-12\/13",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "247",
          "img": "11",
          "name": "2799.00.000-14\/15 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "248",
          "img": "11",
          "name": "2799.00.000-14\/15",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "249",
          "img": "11",
          "name": "2799.00.000-16\/17 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "250",
          "img": "11",
          "name": "2799.00.000-16\/17 ",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "251",
          "img": "11",
          "name": "2799.00.000-18\/19 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "252",
          "img": "11",
          "name": "2799.00.000-18\/19",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "253",
          "img": "11",
          "name": "2799.00.000-20\/21 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "254",
          "img": "11",
          "name": "2799.00.000-20\/21",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.03.000",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "255",
          "img": "8",
          "name": "2968.00.000-06\/07 SKL",
          "group": "Крестовины",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2956.03.000\/-01",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "256",
          "img": "0",
          "name": "2216.00.000",
          "group": "Крестовины",
          "type": "Для линий метрополитена",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "СП566-01|СП568-01",
          "text": "Съезд перекрестный",
          "rzd": "0"
      },
      {
          "id": "257",
          "img": "0",
          "name": "2099.00.000",
          "group": "Крестовины",
          "type": "Для линий метрополитена",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "НМ3.02.000|НМ4.01.000|М5.00.000",
          "text": "Съезд перекрестный",
          "rzd": "0"
      },
      {
          "id": "258",
          "img": "0",
          "name": "2519.00.000-01",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/6",
          "name_cross": "2519.01.000|2519.02.000|2519.03.000|2521.05.000|СП648",
          "text": "Съезд двойной перекрестный",
          "rzd": "1"
      },
      {
          "id": "259",
          "img": "46",
          "name": "1683.00.000",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/11",
          "name_cross": "1683.01.000|СП557|СП559",
          "text": "Пересечение глухое",
          "rzd": "1"
      },
      {
          "id": "260",
          "img": "47",
          "name": "1684.00.000",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "1684.01.000|СП566|СП568",
          "text": "Пересечение глухое",
          "rzd": "1"
      },
      {
          "id": "261",
          "img": "49",
          "name": "2521.00.000-02",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/6",
          "name_cross": "2521.00.020-01|2521.05.000",
          "text": "Пересечение глухое",
          "rzd": "1"
      },
      {
          "id": "262",
          "img": "51",
          "name": "Н2576.000",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "2576.100",
          "text": "Пересечение глухое",
          "rzd": "1"
      },
      {
          "id": "263",
          "img": "48",
          "name": "1624.00.000-02",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2643.01.010-01|1624.02.000-01",
          "text": "Пересечение глухое",
          "rzd": "1"
      },
      {
          "id": "264",
          "img": "48",
          "name": "1624.00.000-02",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1435",
          "track": "1\/9",
          "name_cross": "2643.01.010-01|1624.02.000-01",
          "text": "Пересечение глухое",
          "rzd": "1"
      },
      {
          "id": "265",
          "img": "0",
          "name": "1685.00.000",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "2\/11",
          "name_cross": "1685.01.000|СП555|СП551",
          "text": "Пересечение глухое",
          "rzd": "1"
      },
      {
          "id": "266",
          "img": "50",
          "name": "1686.00.000",
          "group": "Крестовины",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "1657.000|СП516|СП508",
          "text": "Пересечение глухое",
          "rzd": "1"
      },
      {
          "id": "267",
          "img": "1",
          "name": "2832.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/22",
          "name_cross": "2832.01.000,-01|2832.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "268",
          "img": "22",
          "name": "1323.00.000-10\/11",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2451.05.010,-01|2451.05.020,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "269",
          "img": "23",
          "name": "2451.00.000",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2451.05.010,-01|2451.05.020,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "270",
          "img": "2",
          "name": "2870.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2870.01.000,-01|2870.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "271",
          "img": "2",
          "name": "2870.00.000-00 (SKL)",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/18",
          "name_cross": "2870.01.000,-01|2870.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "272",
          "img": "2",
          "name": "2750.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "273",
          "img": "2",
          "name": "2750.00.000 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "274",
          "img": "4",
          "name": "2726.00.000 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.01.000,-01|2726.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "275",
          "img": "4",
          "name": "2726.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.01.000,-01|2726.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "276",
          "img": "17",
          "name": "2768.00.000 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2768.01.000,-01|2768.02.000,-01|2768.01.000,-01 РК2|2768.02.000,-01 РК2|2768.01.000,-10,-11|2768.02.000,-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "277",
          "img": "17",
          "name": "2768.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2768.01.000,-01|2768.02.000,-01|2768.01.000,-01 РК2|2768.02.000,-01 РК2|2768.01.000,-10,-11|2768.02.000,-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "278",
          "img": "13",
          "name": "2285.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2285.01.010,-01|2285.01.020,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "279",
          "img": "19",
          "name": "2844.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1072",
          "track": "1\/11",
          "name_cross": "2844.01.000,-01|2844.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "280",
          "img": "14",
          "name": "2878.00.000 SKL",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2878.01.000,-01|2878.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "281",
          "img": "14",
          "name": "2878.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2878.01.000,-01|2878.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "282",
          "img": "15",
          "name": "2901.00.000 SKL",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2901.01.000,-01|2901.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "283",
          "img": "15",
          "name": "2901.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2901.01.000,-01|2901.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "284",
          "img": "16",
          "name": "2925.00.000 SKL",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2925.01.000,-01|2925.02.000,-01|2925.01.000,-01 РК2|2925.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "285",
          "img": "16",
          "name": "2925.00.000",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2925.01.000,-01|2925.02.000,-01|2925.01.000,-01 РК2|2925.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "286",
          "img": "5",
          "name": "2956.00.000-04\/05 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2956.01.000\/-01|2956.02.000\/-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "287",
          "img": "0",
          "name": "2433.00.000-04\/05",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2433.03.010,-01|2433.03.020,-01|2433.03.010,-01 РК2|2433.03.020,-01 РК2|СП524-06\/-07|СП525-06\/-07|СП524-10,-11|СП525-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "288",
          "img": "0",
          "name": "2433.00.000-56\/57",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП524-12\/-13|СП525-12\/-13",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "289",
          "img": "0",
          "name": "1740.00.000-01\/03",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "1740.01.010,-01|1740.01.020,-01|1740.01.010,-01 РК2|1740.02.010,-01 РК2|1740.01.010,-10,-11|1740.01.020,-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "290",
          "img": "0",
          "name": "1740.00.000-08\/09",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "1740.01.010,-01|1740.01.020,-01|1740.01.010,-10,-11|1740.01.020,-10,-12|2768.03.000",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "291",
          "img": "0",
          "name": "2193.00.000-06\/07",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2193.04.010,-01|2193.04.020,-01|2193.04.010,-01 РК2|2193.04.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "292",
          "img": "0",
          "name": "2688.00.000-02\/03",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2688.01.010,-01|2688.01.020,-01|2688.01.010,-01 РК2|2688.01.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "293",
          "img": "0",
          "name": "2450.00.000 НПК",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2450.01.010|2450.01.020",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "294",
          "img": "39",
          "name": "1848.00.000-10",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "1848.01.010-10,-12|1848.01.010-10,-12 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "295",
          "img": "26",
          "name": "2717-10\/11",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2717.01.010,-01|2717.01.020,-01|2717.01.010,-01 РК2|2717.01.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "296",
          "img": "27",
          "name": "2764.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2764.01.000,-01|2764.02.000,-01|2764.01.000,-01 РК2|2764.02.000,-01 РК2|2764.01.000,-10,-11|2764.02.000,-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "297",
          "img": "29",
          "name": "2771.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2771.01.000,-01|2771.02.000,-01|2771.01.000,-01 РК2|2771.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "298",
          "img": "31",
          "name": "2773.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2773.01.000,-01|2773.02.000,-01|2773.01.000,-01 РК2|2773.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "299",
          "img": "34",
          "name": "2802.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2802.01.000,-01|2802.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "300",
          "img": "35",
          "name": "2851.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2851.01.000,-01|2851.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "301",
          "img": "33",
          "name": "2759.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2759.01.000,-01|2759.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "302",
          "img": "12",
          "name": "2796.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "303",
          "img": "18",
          "name": "2769.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2768.01.000,-01|2768.02.000,-01|2768.01.000,-01 РК2|2768.02.000,-01 РК2|2768.01.000,-10,-11|2768.02.000,-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "304",
          "img": "20",
          "name": "2845.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1067",
          "track": "1\/9",
          "name_cross": "2844.01.000,-01|2844.02.000,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "305",
          "img": "0",
          "name": "2843.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2843.01.000,-01|2843.02.000,-01|2843.01.000,-01 РК2|2843.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "306",
          "img": "0",
          "name": "2843.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1530",
          "track": "1\/9",
          "name_cross": "2843.01.000,-01|2843.02.000,-01|2843.01.000,-01 РК2|2843.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "307",
          "img": "0",
          "name": "2434.00.000-04\/05",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2433.03.010,-01 РК2|2433.03.020,-01 РК2|СП524-06\/-07|СП525-06\/-07|СП524-10,-11|СП525-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "308",
          "img": "0",
          "name": "2434.00.000-40\/41",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП524-12\/-13|СП525-12\/-13",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "309",
          "img": "0",
          "name": "2215.00.000-04\/05",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1740.01.010,-01|1740.01.020,-01|1740.01.010,-01 РК2|1740.02.010,-01 РК2|1740.01.010,-10,-11|1740.01.020,-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "310",
          "img": "0",
          "name": "2215.00.000-08\/09",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1740.01.010,-01|1740.01.020,-01|1740.01.010,-01 РК2|1740.02.010,-01 РК2|1740.01.010,-10,-11|1740.01.020,-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "311",
          "img": "0",
          "name": "2244.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2193.04.010,-01|2193.04.020,-01|2193.04.010,-01 РК2|2193.04.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "312",
          "img": "28",
          "name": "2766.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2764.01.000,-01|2764.02.000,-01|2764.01.000,-01 РК2|2764.02.000,-01 РК2|2764.01.000,-10,-11|2764.02.000,-10,-11",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "313",
          "img": "32",
          "name": "2721.00.000-02\/03",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2773.01.000,-01|2773.02.000,-01|2773.01.000,-01 РК2|2773.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "314",
          "img": "30",
          "name": "2772.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2771.01.000,-01|2771.02.000,-01|2771.01.000,-01 РК2|2771.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "315",
          "img": "0",
          "name": "1580.00.000",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1580.01.010,-01|1580.01.020,-01|1580.01.010,-01 РК2|1580.01.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "316",
          "img": "0",
          "name": "2227.00.000",
          "group": "Ремкомплекты",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2227.01.010,-01|1580.01.020,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "317",
          "img": "43",
          "name": "2869.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "2869.01.000,-01|2869.02.000,-01|2869.01.000,-01 РК2|2869.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "318",
          "img": "43",
          "name": "2869.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1530",
          "track": "1\/9",
          "name_cross": "2869.01.000,-01|2869.02.000,-01|2869.01.000,-01 РК2|2869.02.000,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "319",
          "img": "0",
          "name": "267А.00.000-02\/03",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "267А110.00,-01|267А120.00,-01|267А110.00,-01 РК2|267А120.00,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "320",
          "img": "0",
          "name": "267А.00.000-04\/05",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "267А110.00,-01|267А120.00,-01|267А110.00,-01 РК2|267А120.00,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "321",
          "img": "0",
          "name": "267А.00.000-06\/07",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "267А110.00,-01|267А120.00,-01|267А110.00,-01 РК2|267А120.00,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "322",
          "img": "0",
          "name": "665121.001\/-01",
          "group": "Ремкомплекты",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "ЛПТП.668341.001,-01|ЛПТП.668342.001,-01|ЛПТП.668341.001,-01 РК2|ЛПТП.668342.001,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "323",
          "img": "0",
          "name": "665121.001\/-02\/03",
          "group": "Ремкомплекты",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "ЛПТП.668341.001,-01|ЛПТП.668342.001,-01|ЛПТП.668341.001,-01 РК2|ЛПТП.668342.001,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "324",
          "img": "0",
          "name": "665121.001\/-04\/05",
          "group": "Ремкомплекты",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "ЛПТП.668341.001,-01|ЛПТП.668342.001,-01|ЛПТП.668341.001,-01 РК2|ЛПТП.668342.001,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "325",
          "img": "0",
          "name": "665121.001\/-06\/07",
          "group": "Ремкомплекты",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "ЛПТП.668341.001,-01|ЛПТП.668342.001,-01|ЛПТП.668341.001,-01 РК2|ЛПТП.668342.001,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "326",
          "img": "0",
          "name": "ЛПТП665121.100\/-01",
          "group": "Ремкомплекты",
          "type": "Для путей промышленных предприятий и портов",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/9",
          "name_cross": "ЛПТП668.341.100,-01|ЛПТП668.342.100,-02",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "327",
          "img": "0",
          "name": "ЛПТП665121.101\/-01",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "ЛПТП.668341.101,-01|ЛПТП.668342.101,-01|ЛПТП.668341.101,-01 РК2|ЛПТП.668342.101,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "328",
          "img": "0",
          "name": "ЛПТП665121.101-02\/-03",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "ЛПТП.668341.101,-01|ЛПТП.668342.101,-01|ЛПТП.668341.101,-01 РК2|ЛПТП.668342.101,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "329",
          "img": "0",
          "name": "2690.00.000-18\/19",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/7",
          "name_cross": "2690.06.010,-01|2690.06.020,-01|2690.06.010,-01 РК2|2690.06.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "330",
          "img": "0",
          "name": "2690.00.000-20\/21",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/7",
          "name_cross": "2690.06.010,-01|2690.06.020,-01|2690.06.010,-01 РК2|2690.06.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "331",
          "img": "0",
          "name": "НЛПТП665121.103\/-01",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1524",
          "track": "1\/7",
          "name_cross": "ЛПТП.668341.103,-01|ЛПТП.668342.103,-01",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "332",
          "img": "40",
          "name": "2307.00.000-02",
          "group": "Ремкомплекты",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "2307.01.010,-01|2307.01.010,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "333",
          "img": "40",
          "name": "2307.00.000-20\/21",
          "group": "Ремкомплекты",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "2307.01.010-08,-09|2307.01.010-08,-09 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "334",
          "img": "21",
          "name": "2628.00.000-06",
          "group": "Ремкомплекты",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "2628.01.010,-01|2628.01.010,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "335",
          "img": "36",
          "name": "2642.00.000-04\/05",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "СП574,-01|СП575,-01|2642.03.010,-01 РК2|2642.03.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "336",
          "img": "37",
          "name": "2643.00.000-04\/05",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1435",
          "track": "1\/9",
          "name_cross": "СП574,-01|СП575,-01|2642.03.010,-01 РК2|2642.03.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "337",
          "img": "37",
          "name": "2643.00.000-04\/05",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "СП574,-01|СП575,-01|2642.03.010,-01 РК2|2642.03.020,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "338",
          "img": "45",
          "name": "1623.00.000-03",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/9",
          "name_cross": "1623.01.010-04\/-05|1623.01.020-04\/-05|1623.01.010-04\/-05 РК2|1623.01.020-04\/-05 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "339",
          "img": "45",
          "name": "1623.00.000-03",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1535",
          "track": "1\/9",
          "name_cross": "1623.01.010-04\/-05|1623.01.020-04\/-05|1623.01.010-04\/-05 РК2|1623.01.020-04\/-05 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "340",
          "img": "41",
          "name": "1581.00.000",
          "group": "Ремкомплекты",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "1581.01.010-02,-03|1581.01.010,-01 РК2",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "341",
          "img": "42",
          "name": "2212.00.000",
          "group": "Ремкомплекты",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "1\/6",
          "name_cross": "2212.01.010,-01",
          "text": "Стрелочный перевод ",
          "rzd": "1"
      },
      {
          "id": "342",
          "img": "38",
          "name": "1909.00.000",
          "group": "Ремкомплекты",
          "type": "Для линий метрополитена",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1524",
          "track": "1\/5",
          "name_cross": "1909.01.010,-01…-07",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "343",
          "img": "0",
          "name": "2673.00.000",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "750",
          "track": "1\/5",
          "name_cross": "2673.01.010,-01|2673.01.010-02,-03",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "344",
          "img": "0",
          "name": "ПОНШ933.00.00.000",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р33",
          "cross": "900",
          "track": "1\/5",
          "name_cross": "ПОНШ933.20.00-001,-01|ПОНШ933.20.00.002,-01",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "345",
          "img": "0",
          "name": "ПШОН 733.00.000",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р33",
          "cross": "750",
          "track": "1\/5",
          "name_cross": "ПОНШ933.20.00-001,-01|ПОНШ933.20.00.002,-01",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "346",
          "img": "0",
          "name": "ПШОУ 20.00.000",
          "group": "Ремкомплекты",
          "type": "Для путей лесной, торфяной промышленности и шахт",
          "bar": "Деревянный брус",
          "rail": "Р33",
          "cross": "900",
          "track": "1\/4",
          "name_cross": "ПОНШ933.20.00-001,-01|ПОНШ933.20.00.002,-01",
          "text": "Стрелочный перевод ",
          "rzd": "0"
      },
      {
          "id": "347",
          "img": "0",
          "name": "Н-С30-00.00.000",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Т62",
          "cross": "1524",
          "track": "",
          "name_cross": "Н-С30-01.01.000|Н-С30-02.01.000",
          "text": "Стрелка трамвайная",
          "rzd": "0"
      },
      {
          "id": "348",
          "img": "0",
          "name": "Н-С50-00.00.000",
          "group": "Ремкомплекты",
          "type": "Для трамвайных путей",
          "bar": "Деревянный брус",
          "rail": "Т62",
          "cross": "1524",
          "track": "",
          "name_cross": "Н-С50-01.01.000|Н-С50-02.01.001",
          "text": "Стрелка трамвайная",
          "rzd": "0"
      },
      {
          "id": "349",
          "img": "3",
          "name": "2833.00.000-00\/01",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/22",
          "name_cross": "2832.01.000,-01|2832.02.000,-01",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "350",
          "img": "9",
          "name": "2728.00.000 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.01.000,-01|2726.02.000,-01",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "351",
          "img": "10",
          "name": "2728.00.000 ",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2726.01.000,-01|2726.02.000,-01",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "352",
          "img": "11",
          "name": "2799.00.000-00\/01 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "353",
          "img": "11",
          "name": "2799.00.000-00\/01 ",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "354",
          "img": "11",
          "name": "2799.00.000-02\/03 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "355",
          "img": "11",
          "name": "2799.00.000-02\/03",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "356",
          "img": "11",
          "name": "2799.00.000-04\/05 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "357",
          "img": "11",
          "name": "2799.00.000-04\/05 ",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "358",
          "img": "11",
          "name": "2799.00.000-06\/07 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "359",
          "img": "11",
          "name": "2799.00.000-06\/07 ",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "360",
          "img": "11",
          "name": "2799.00.000-08\/09 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "361",
          "img": "11",
          "name": "2799.00.000-08\/09",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "362",
          "img": "11",
          "name": "2799.00.000-10\/11 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "363",
          "img": "11",
          "name": "2799.00.000-10\/11",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "364",
          "img": "11",
          "name": "2799.00.000-12\/13 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "365",
          "img": "11",
          "name": "2799.00.000-12\/13",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "366",
          "img": "11",
          "name": "2799.00.000-14\/15 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "367",
          "img": "11",
          "name": "2799.00.000-14\/15",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "368",
          "img": "11",
          "name": "2799.00.000-16\/17 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "369",
          "img": "11",
          "name": "2799.00.000-16\/17 ",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "370",
          "img": "11",
          "name": "2799.00.000-18\/19 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "371",
          "img": "11",
          "name": "2799.00.000-18\/19",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "372",
          "img": "11",
          "name": "2799.00.000-20\/21 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "373",
          "img": "11",
          "name": "2799.00.000-20\/21",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2750.01.000,-01|2750.02.000,-01|2750.01.000,-01 РК2|2750.02.000,-01 РК2",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "374",
          "img": "8",
          "name": "2968.00.000-06\/07 SKL",
          "group": "Ремкомплекты",
          "type": "Для магистральных путей",
          "bar": "Железобетонный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "1\/11",
          "name_cross": "2956.01.000,-01|2956.02.000,-01",
          "text": "Съезд одиночный",
          "rzd": "1"
      },
      {
          "id": "375",
          "img": "0",
          "name": "2216.00.000",
          "group": "Ремкомплекты",
          "type": "Для линий метрополитена",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "2433.03.010,-01 РК2|2433.03.020,-01 РК2|СП524-06\/-07|СП525-06\/-07|СП524-10,-11 РК2|СП525-10,-11 РК2",
          "text": "Съезд перекрестный",
          "rzd": "0"
      },
      {
          "id": "376",
          "img": "0",
          "name": "2099.00.000",
          "group": "Ремкомплекты",
          "type": "Для линий метрополитена",
          "bar": "Деревянный брус",
          "rail": "Р50",
          "cross": "1520",
          "track": "2\/9",
          "name_cross": "СП574,-01|СП575,-01|2642.03.010,-01 РК2|2642.03.020,-01 РК2",
          "text": "Съезд перекрестный",
          "rzd": "0"
      },
      {
          "id": "377",
          "img": "44",
          "name": "2519.00.000-01",
          "group": "Ремкомплекты",
          "type": "Для приёмо-отправочных, сортировочных, станционных путей",
          "bar": "Деревянный брус",
          "rail": "Р65",
          "cross": "1520",
          "track": "2\/6",
          "name_cross": "2307.01.010|-01",
          "text": "Съезд двойной перекрестный",
          "rzd": "1"
      },
      {
          "id": "378",
          "img": "0",
          "name": "<strong>Дробилка щековая-ЩДС-16*9 СМД-16 Д<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "379",
          "img": "0",
          "name": "Футеровка нижняя",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 162-0-35",
          "track": "55",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "380",
          "img": "0",
          "name": "Футеровка верхняя ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "220-0-0-2",
          "track": "110",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "381",
          "img": "0",
          "name": "<strong>Щековая дробилка ЩС-60*90  СМД 16 Д<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "382",
          "img": "0",
          "name": "Плита дробящая подвижная",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 162-2-1",
          "track": "770",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "383",
          "img": "0",
          "name": "Плита дробящая неподвижная",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 162-0-1",
          "track": "785",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "384",
          "img": "0",
          "name": "Плита дробящая подвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "162-2-1-Б",
          "track": "770",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "385",
          "img": "0",
          "name": "Плита дробящая неподвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "162-0-1-Б",
          "track": "905",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "386",
          "img": "0",
          "name": "<strong>Дробилка щековая ЩДС 11-2,5*9,0 СМД –108<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "387",
          "img": "0",
          "name": "Плита  дробящая неподвижная для выходной щели 50-80",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " СМД –108-00-00-001",
          "track": "260",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "388",
          "img": "0",
          "name": "Плита дробящая неподвижная для выходной щели 30-50 ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " СМД 108-00-00-009",
          "track": "290",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "389",
          "img": "0",
          "name": "Плита дробящая подвижная для выходной щели 50-80  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМД 108-02-00-004",
          "track": "300",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "390",
          "img": "0",
          "name": "Плита дробящая подвижная для выходной щели 30-50  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМД 108-02-00-022",
          "track": "292",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "391",
          "img": "0",
          "name": "Клин верхний правый ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМД 108.00.00.002",
          "track": "27",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "392",
          "img": "0",
          "name": "Клин нижний левый",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " СМД 108-00-00-004",
          "track": "19,8",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "393",
          "img": "0",
          "name": "Клин нижний правый ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМД 108-00-00-005",
          "track": "19,8",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "394",
          "img": "0",
          "name": "Клин верхний левый ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМД 108-00-00-003",
          "track": "27",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "395",
          "img": "0",
          "name": "<strong>Щековая дробилка СМД 110Д<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "396",
          "img": "0",
          "name": "Плита дробящая неподвижная для выходной щели 80-100 ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " СМД 110-00-00-002",
          "track": "865",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "397",
          "img": "0",
          "name": "Плита дробящая подвижная для выходной щели 80-100  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "4845011016",
          "track": "920",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "398",
          "img": "0",
          "name": "Плита дробящая подвижная для выходной щели 100-160 ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "4845011018",
          "track": "800",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "399",
          "img": "0",
          "name": "Плита дробящая неподвижная для выходной щели 100-160  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМД110-0000-001",
          "track": "800",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "400",
          "img": "0",
          "name": "Клин верхний левый",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "4845000019",
          "track": "135",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "401",
          "img": "0",
          "name": "Клин верхний правый ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "4845000018",
          "track": "135",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "402",
          "img": "0",
          "name": "Клин нижний правый ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "4845000021",
          "track": "80",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "403",
          "img": "0",
          "name": "Клин нижний левый",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "4845000022",
          "track": "80",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "404",
          "img": "0",
          "name": "<strong>Дробилка СМ-58Б<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "405",
          "img": "0",
          "name": "Плита дробящая СМД-58Б ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3410-01-030",
          "track": "90",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "406",
          "img": "0",
          "name": "Плита дробящая СМД-58Б ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3410-02-020",
          "track": "260",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "407",
          "img": "0",
          "name": "Плита дробящая СМД-58Б  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3410-01-50",
          "track": "270",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "408",
          "img": "0",
          "name": "Плита дробящая СМД-58Б ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3410-02-010",
          "track": "210",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "409",
          "img": "0",
          "name": "<strong>Дробилка СМД 118<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "410",
          "img": "0",
          "name": "Плита дробящая неподвижная",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3452-10-012",
          "track": "1350",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "411",
          "img": "0",
          "name": "Плита дробящая подвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3452-02-006",
          "track": "1900",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "412",
          "img": "0",
          "name": "Плита дробящая подвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3452-02-007",
          "track": "1640",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "413",
          "img": "0",
          "name": "Футеровка (клин) верхняя правая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3452-10-003",
          "track": "355",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "414",
          "img": "0",
          "name": "Футеровка (клин) верхняя левая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3452-10-004",
          "track": "355",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "415",
          "img": "0",
          "name": "Футеровка (клин) нижняя правая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3452-10-005",
          "track": "200",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "416",
          "img": "0",
          "name": "Футеровка (клин) нижняя левая  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3452-10-006",
          "track": "200",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "417",
          "img": "0",
          "name": "Сухарь",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3452.03.203",
          "track": "201",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "418",
          "img": "0",
          "name": "Сухарь",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3452.02.003.2",
          "track": "265",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "419",
          "img": "0",
          "name": "<strong>Дробилка 109Д<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "420",
          "img": "0",
          "name": "Плита дробящая неподвижная для выходной щели 50-80 ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " СМД 109-00-00-002",
          "track": "483",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "421",
          "img": "0",
          "name": "Плита дробящая неподвижная для выходной щели 50-80 ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "4844902016",
          "track": "520",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "422",
          "img": "0",
          "name": "<strong>Дробилка СМД-741<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "423",
          "img": "0",
          "name": "Плита дробящая неподвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "  254-0-0-1",
          "track": "295",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "424",
          "img": "0",
          "name": "Плита дробящая неподвижная для выходной щели 40-50  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 254-0-0-1-А",
          "track": "459",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "425",
          "img": "0",
          "name": "Плита дробящая подвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "254-2-0-1А",
          "track": "520",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "426",
          "img": "0",
          "name": "<strong>Дробилка  СМД111-1<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "427",
          "img": "0",
          "name": "Плита дробящая неподвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3440-01-110",
          "track": "840",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "428",
          "img": "0",
          "name": "Плита дробящая подвижная нижняя  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3440-02-020",
          "track": "960",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "429",
          "img": "0",
          "name": "Плита дробящая подвижная верхняя ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3440-02-010",
          "track": "880",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "430",
          "img": "0",
          "name": "Футеровка боковая правая (клин)",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "  3440-01-008",
          "track": "250",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "431",
          "img": "0",
          "name": "Футеровка боковая нижняя левая (клин)  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3440-01-009",
          "track": "250",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "432",
          "img": "0",
          "name": "Футеровка боковая верхняя правая (клин) ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3440-01-006",
          "track": "320",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "433",
          "img": "0",
          "name": "Футеровка боковая верхняя левая   ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3440-01-007",
          "track": "320",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "434",
          "img": "0",
          "name": "<strong>Зубья ковша<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "435",
          "img": "0",
          "name": "Зуб ковша ЭКГ 12,5    ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3537-01-00-007, 8199-00-24-001А   ",
          "track": "410",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "436",
          "img": "0",
          "name": "Зуб ковша ЭКГ 12,5    ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "  0800-10-98",
          "track": "335",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "437",
          "img": "0",
          "name": "Зуб ковша ЭКГ 8     ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 1010-29-02Б",
          "track": "185",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "438",
          "img": "0",
          "name": "Зуб ковша  ЭКГ 4,6 ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 1041-01-01, Э589-322,1080-02-11-1",
          "track": "130",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "439",
          "img": "0",
          "name": "Зуб ковша самозатачивающийся ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 313-5-4-А",
          "track": "130",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "440",
          "img": "0",
          "name": "<strong>Мельница Ш-10<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "441",
          "img": "0",
          "name": "Плита торцевая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " РК-1575",
          "track": "55,6",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "442",
          "img": "0",
          "name": "Клин затяжной ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " РК-1585",
          "track": "13,6",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "443",
          "img": "0",
          "name": "Клин затяжной ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " РК-1587",
          "track": "11,1",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "444",
          "img": "0",
          "name": "Клин затяжной  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "РК-1584",
          "track": "16",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "445",
          "img": "0",
          "name": "Клин затяжной ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 4-169223",
          "track": "17,5",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "446",
          "img": "0",
          "name": "Броня клина ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3-85384",
          "track": "87",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "447",
          "img": "0",
          "name": "Броня клина ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " РК-1613",
          "track": "78",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "448",
          "img": "0",
          "name": "Броня клина  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "РК-1618",
          "track": "91",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "449",
          "img": "0",
          "name": "Броня барабана ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3-85383",
          "track": "94",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "450",
          "img": "0",
          "name": "Броня барабана ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " РК-1617",
          "track": "79",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "451",
          "img": "0",
          "name": "Броня барабана  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "РК-1616",
          "track": "95",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "452",
          "img": "0",
          "name": "Броня торцевой стенки ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " РК- 2036",
          "track": "55",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "453",
          "img": "0",
          "name": "Броня плиты лаза",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "  РК-1615",
          "track": "52",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "454",
          "img": "0",
          "name": "Броня под лаз ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " РК-1614",
          "track": "47",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "455",
          "img": "0",
          "name": "Броня торцевая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К-20564",
          "track": "272",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "456",
          "img": "0",
          "name": "Клин затяжной ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " К5-166",
          "track": "30",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "457",
          "img": "0",
          "name": "Клин затяжной",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "  К5-167",
          "track": "17,5",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "458",
          "img": "0",
          "name": "<strong>Мельница Ш-32<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "459",
          "img": "0",
          "name": "Броня барабана цилиндрическая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 480сб 1Д-02",
          "track": "180",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "460",
          "img": "0",
          "name": "Броня клиновая цилиндрическая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Д-35",
          "track": "172",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "461",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Д-11",
          "track": "221",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "462",
          "img": "0",
          "name": "Броня коническая клиновая     ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Д12",
          "track": "239",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "463",
          "img": "0",
          "name": "Броня коническая клиновая   ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д13",
          "track": "221",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "464",
          "img": "0",
          "name": "Клин неподвижный цилиндрической  брони  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Д-04",
          "track": "26",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "465",
          "img": "0",
          "name": "Клин неподвижный цилиндрической  брони  3-4 ряда",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д-34",
          "track": "60",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "466",
          "img": "0",
          "name": "Клин цилиндрической  брони затяжной ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д05",
          "track": "17",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "467",
          "img": "0",
          "name": "Клин неподвижный конический ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "  К-1875-5",
          "track": "60",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "468",
          "img": "0",
          "name": "Клин конический затяжной 3р. ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Д31",
          "track": "35",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "469",
          "img": "0",
          "name": "Клин конический затяжной 2р. ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Д32",
          "track": "31",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "470",
          "img": "0",
          "name": "Клин конический затяжной 1р. ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "  Д33",
          "track": "22",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "471",
          "img": "0",
          "name": "Клин неподвижный конич. Затяжной 3р",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Д30",
          "track": "25",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "472",
          "img": "0",
          "name": "Броня торцевая  480сбосб1 ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д01",
          "track": "256",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "473",
          "img": "0",
          "name": "Броня торцевая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д25",
          "track": "260",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "474",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д27",
          "track": "70",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "475",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д08",
          "track": "186",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "476",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д28",
          "track": "76",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "477",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д09",
          "track": "206",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "478",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д29",
          "track": "70",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "479",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д10",
          "track": "186",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "480",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д14",
          "track": "188",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "481",
          "img": "0",
          "name": "Броня коническая клиновая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Д15",
          "track": "206",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "482",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д16",
          "track": "188",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "483",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д17",
          "track": "159",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "484",
          "img": "0",
          "name": "Броня коническая клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д18",
          "track": "177",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "485",
          "img": "0",
          "name": "Броня коническая клиновая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Д19",
          "track": "161",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "486",
          "img": "0",
          "name": "Броня торцевая клиновая    ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д20",
          "track": "116",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "487",
          "img": "0",
          "name": "Броня торцевая клиновая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К1988-1",
          "track": "120",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "488",
          "img": "0",
          "name": "Клин неподвижный цилиндрической брони",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Д36",
          "track": "64",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "489",
          "img": "0",
          "name": "Клин неподвижный конический  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К1715",
          "track": "44",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "490",
          "img": "0",
          "name": "Клин неподвижный ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К1716",
          "track": "40",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "491",
          "img": "0",
          "name": "Клин подвижный  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К1712",
          "track": "30",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "492",
          "img": "0",
          "name": "Клин подвижный конический затяжной ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К1713",
          "track": "39",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "493",
          "img": "0",
          "name": "<strong>Дробилка СМ-170Б<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "494",
          "img": "0",
          "name": "Футеровка  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМ170Б-1-0-9",
          "track": "15",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "495",
          "img": "0",
          "name": "Футеровка  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМ170Б6-0-2, (Т4-27)",
          "track": "18,3",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "496",
          "img": "0",
          "name": "Молоток ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Т-4-18а",
          "track": "11,3",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "497",
          "img": "0",
          "name": "Молоток",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Т-2214",
          "track": "11,4",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "498",
          "img": "0",
          "name": "Футеровка дробильного бруса ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Т-4-27",
          "track": "19",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "499",
          "img": "0",
          "name": "<strong>Дисковый питатель ДТ-200<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "500",
          "img": "0",
          "name": "Плита футеровочная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " К-1579А",
          "track": "68",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "501",
          "img": "0",
          "name": "<strong>Дробилка М-20*30<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "502",
          "img": "0",
          "name": "Футеровка ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3591-00-201-00-23",
          "track": "110",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "503",
          "img": "0",
          "name": "<strong>Мельница Ш16<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "504",
          "img": "0",
          "name": "Клин затяжной",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " К5-174",
          "track": "15",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "505",
          "img": "0",
          "name": "Клин затяжной ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К5-175",
          "track": "10,1",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "506",
          "img": "0",
          "name": "Торцевая броня внутренняя",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " К5-176",
          "track": "62",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "507",
          "img": "0",
          "name": "Торцевая броня верхняя",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " К5-177",
          "track": "58,1",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "508",
          "img": "0",
          "name": "Броня клина  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К5-181",
          "track": "74",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "509",
          "img": "0",
          "name": "Броня барабана  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К5-182",
          "track": "88",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "510",
          "img": "0",
          "name": "Броня барабана",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " К5-185",
          "track": "122",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "511",
          "img": "0",
          "name": "Броня клиновая  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " К5-186",
          "track": "105",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "512",
          "img": "0",
          "name": "<strong>Мельница Ш-25<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "513",
          "img": "0",
          "name": "Броня клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3В2809-1",
          "track": "62,5",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "514",
          "img": "0",
          "name": "Броня барабана  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3В-2809-4",
          "track": "67",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "515",
          "img": "0",
          "name": "Броня люка ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3В-2809-6",
          "track": "54",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "516",
          "img": "0",
          "name": "Броня люка барабана ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3В-2809-7",
          "track": "80",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "517",
          "img": "0",
          "name": "Броня торцевая  ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3В-2809-8",
          "track": "53",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "518",
          "img": "0",
          "name": "Броня торцевая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3В-2809-9",
          "track": "47",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "519",
          "img": "0",
          "name": "<strong>Мельница ММТ-2000\/2590-730к2000<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "520",
          "img": "0",
          "name": "Било ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "2.0029.00",
          "track": "12",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "521",
          "img": "0",
          "name": "<strong>Мельница ММТ 1500\/2500<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "522",
          "img": "0",
          "name": "Било ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "К –3816",
          "track": "10",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "523",
          "img": "0",
          "name": "<strong>Дробилка 2000\/3000<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "524",
          "img": "0",
          "name": "Молоток",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Т-3213",
          "track": "47",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "525",
          "img": "0",
          "name": "Молоток",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Т-2383",
          "track": "63,9",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "526",
          "img": "0",
          "name": "<strong>Дробилка СМД-114<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "527",
          "img": "0",
          "name": "Било ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "ФА-342",
          "track": "5",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "528",
          "img": "0",
          "name": "Било",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "1044703001",
          "track": "51,8",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "529",
          "img": "0",
          "name": "Било ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3.169.150.2Н",
          "track": "8",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "530",
          "img": "0",
          "name": "<strong>Дробилка СМД-115<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "531",
          "img": "0",
          "name": "Плита дробящая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМД 115.01.01.001А",
          "track": "31",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "532",
          "img": "0",
          "name": "Плита неподвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СМД 115.01.01.002А",
          "track": "45",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "533",
          "img": "0",
          "name": "<strong>Дробилка СНД-97А<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "534",
          "img": "0",
          "name": "Футеровка",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3591.00.101.00.203",
          "track": "110",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "535",
          "img": "0",
          "name": "Молоток",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Т41-02.00.00.12",
          "track": "100",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "536",
          "img": "0",
          "name": "Футеровка ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3591.00.201.0023",
          "track": "110",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "537",
          "img": "0",
          "name": "<strong>Мельница Ш-50А<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "538",
          "img": "0",
          "name": "Броня клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3В 1509-3",
          "track": "63",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "539",
          "img": "0",
          "name": "Броня клиновая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3В 1509-6",
          "track": "84",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "540",
          "img": "0",
          "name": "Броня барабана",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 3В 1509-7",
          "track": "93",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "541",
          "img": "0",
          "name": "Броня барабана ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "3В 1509-8",
          "track": "70",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "542",
          "img": "0",
          "name": "<strong>Дробилка ДЦИ-1035<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "543",
          "img": "0",
          "name": "Плита подвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "1035-1",
          "track": "582",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "544",
          "img": "0",
          "name": "Плита подвижная ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "1035-2",
          "track": "370",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "545",
          "img": "0",
          "name": "<strong>Оборудование для производства железобетонных шпал<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "546",
          "img": "0",
          "name": "Ролик ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Р2773311",
          "track": "17,2",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "547",
          "img": "0",
          "name": "Ролик",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "277308",
          "track": "4,5",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "548",
          "img": "0",
          "name": "Зажим верхний",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " 277313-5.2-1",
          "track": "40",
          "name_cross": "20Л1",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "549",
          "img": "0",
          "name": "Зажим нижний ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "277313-5.3-1",
          "track": "39",
          "name_cross": "20Л1",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "550",
          "img": "0",
          "name": "Полумуфта ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "277-1.2-4",
          "track": "9,4",
          "name_cross": "20Л1",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "551",
          "img": "0",
          "name": "<strong>Бетономешалка СБ 138Б<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "552",
          "img": "0",
          "name": "Лопасть бетоносмесителя ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "СБ-138Б",
          "track": "7,5",
          "name_cross": "20Л1",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "553",
          "img": "0",
          "name": "Броня малая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Б26.000",
          "track": "4",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "554",
          "img": "0",
          "name": "Броня малая",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " Б25.000",
          "track": "72",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "555",
          "img": "0",
          "name": "Броня малая ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "Б24.000",
          "track": "72",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "556",
          "img": "0",
          "name": "Накладка ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "М01.000",
          "track": "30",
          "name_cross": "20Л1",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "557",
          "img": "0",
          "name": "Накладка",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": " М01.001",
          "track": "30",
          "name_cross": "20Л1",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "558",
          "img": "0",
          "name": "Накладка ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "М02.000",
          "track": "30",
          "name_cross": "20Л1",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "559",
          "img": "0",
          "name": "Накладка ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "М02.001",
          "track": "2",
          "name_cross": "20Л1",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "560",
          "img": "0",
          "name": "<strong>Баровая цепь<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "561",
          "img": "0",
          "name": "Лопатка ",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "034.51.00.001",
          "track": "20,7",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "562",
          "img": "0",
          "name": "Звено цепи",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "034.51.00.002",
          "track": "7,3",
          "name_cross": "110Г13Л",
          "text": "ПГС, по сухому",
          "rzd": "0"
      },
      {
          "id": "563",
          "img": "0",
          "name": "<strong>Балочка центрирующая<\/strong>",
          "group": "Прочая",
          "type": "Продукция литейного производства",
          "bar": "",
          "rail": "",
          "cross": "106.00.011-2Л",
          "track": "10,2",
          "name_cross": "20Л1",
          "text": "ПГС, по сырому",
          "rzd": "0"
      },
      {
          "id": "564",
          "img": "17803",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА стрелочного перевода с подвижной крестовиной КЭСП-ЛЭ-17803",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный или железобетонный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "М1\/11 \r\n2726.00.000,\r\n2728.00.000\r\n(для 2728.00.000 - *)",
          "rzd": "0"
      },
      {
          "id": "565",
          "img": "17806",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА стрелочного перевода с переводным устройством КЭСП-ЛЭ-17806",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный или железобетонный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "М1\/11\r\n2750.00.000 (2799.00.000 - *),\r\n2802.00.000,\r\n2771.00.000 (2193.00.000),\r\n2773.00.000 (2688.00.000),\r\n2901.00.000",
          "rzd": "0"
      },
      {
          "id": "566",
          "img": "17807",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА стрелочного перевода КЭСП-ЛЭ-17807",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный или железобетонный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "м.1\/11 2764.00.000 (2433.00.000),\r\nм.1\/9 2766.00.000 (2434.00.000),\r\nм.1\/11 2768.00.000 (1740.00.000),\r\nм.1\/9 2769.00.000 (2215.00.000)",
          "rzd": "0"
      },
      {
          "id": "567",
          "img": "17814",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА стрелочного перевода с подвижной крестовиной КЭСП-ЛЭ-17814",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "железобетонный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "М1\/18 2870.00.000",
          "rzd": "0"
      },
      {
          "id": "568",
          "img": "17822",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА двойного перекрёстного стрелочного перевода КЭСП-ЛЭ-17822",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "железобетонный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "М1\/9\r\n2843.00.000,\r\n2869.00.00\r\n(1580.00.000)",
          "rzd": "0"
      },
      {
          "id": "569",
          "img": "17823",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА стрелочного перевода КЭСП-ЛЭ-17823",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "железобетонный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "М1\/22 2832.00.000",
          "rzd": "0"
      },
      {
          "id": "570",
          "img": "17845",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА симметричного стрелочного перевода КЭСП-ЛЭ-17845",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный или железобетонный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "М1\/6\r\n2628.00.000,\r\n2946.00.000",
          "rzd": "0"
      },
      {
          "id": "571",
          "img": "17855",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА стрелочного перевода марки  КЭСП-ЛЭ-17855",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "М1\/9 2497.00.000,\r\nМ1\/11 2642.00.000,\r\nМ1\/9 2643.00.000,\r\nМ1\/6 1581.00.000",
          "rzd": "0"
      },
      {
          "id": "572",
          "img": "0",
          "name": "Комплект электрообогрева съезда одиночного КЭСП-ЛЭ-17824",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "железобетонный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "М1\/22 2833.00.000 (2832)",
          "rzd": "0"
      },
      {
          "id": "573",
          "img": "17805",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА сбрасывающей стрелки КЭСП-ЛЭ-17805",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный или железобетонный",
          "rail": "Р65",
          "cross": "1520",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "2663.00.000,\r\n2884.00.000",
          "rzd": "0"
      },
      {
          "id": "574",
          "img": "17846",
          "name": "КОМПЛЕКТ ЭЛЕКТРООБОГРЕВА колесосбрасывающего башмака (КСБ) проекта 495.000 КЭСП-ЛЭ-17846",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный или железобетонный",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "комплексное оборудование стрелок электронагревателями, рельсовыми термодатчиками, теплоизолирующими экранами, путевыми ящиками, защитной и фиксирующей",
          "text": "495.000",
          "rzd": "0"
      },
      {
          "id": "575",
          "img": "10000",
          "name": "ШКАФЫ УПРАВЛЕНИЯ ЭЛЕКТРООБОГРЕВОМ стрелочных переводов модернизированные ШУЭС-М",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный или железобетонный",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "работа в составе систем обогрева в качестве вводно-распределительного и регулирующего устройства электропитания нагревательных элементов, установленны",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "576",
          "img": "10001",
          "name": "ТЕРМОДАТЧИК РЕЛЬСОВЫЙ с термопреобразователем и обоймой, с кабельным выводом ТДР-074",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный или железобетонный",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "измерение температуры рамных рельсов и усовиков стрелочных переводов, оборудуемых электрообогревом",
          "text": "",
          "rzd": "0"
      },
      {
          "id": "577",
          "img": "10002",
          "name": "ШКАФЫ СТАНЦИОННЫЕ СЕРВЕРНЫЕ системы электрообогрева стрелочных переводов ССШ-ЭО",
          "group": "Прочая",
          "type": "Комплекты электрообогрева",
          "bar": "деревянный или железобетонный",
          "rail": "",
          "cross": "",
          "track": "",
          "name_cross": "организация расширенной схемы управления и мониторинга состояния устройств электрообогрева стрелочных переводов на станции",
          "text": "",
          "rzd": "0"
      }
      ]
      
      }
      ]

    ifOtherGroupFilter()
    

    function ifOtherGroupFilter(){        
        var Table:HTMLTableElement=document.querySelector('.finalTable')  
        let tempList = []

        if (activeCatSwitchValue ==='Прочая'){
            let checkedO = false
            Prods[0].data.forEach(function(n){
                if (n.group === 'Прочая'){
                    tempList.push(n)
                    finalList.push(n)
                }
            })
            firstPickInputs.forEach(function(e){
                if (e.checked){
                    if (checkedO===false){
                        finalList=[]
                        checkedO = true
                    } 
                        tempList.forEach(function(c){
                            if (c.type === e.value){
                                finalList.push(c)
                            }
                        })
                    }
                }
        )
            let prochRows = `<table class="finalTable">
            <tr>
                <th>Наименование</th>
                <th>Назначение</th>
                <th>Вид бруса</th>
                <th>Тип рельса</th>
                <th>Ширина колеи</th>
                <th>Применяемость</th>
            </tr>
        </table>`
        resultTable.innerHTML = prochRows
        var Table:HTMLTableElement=document.querySelector('.finalTable')
        addTables(4)
            return
        }
        


        let picksbruce = document.querySelector('.picksbruce')
        let pickskol = document.querySelector('.pickskol')
        let picksrels = document.querySelector('.picksrels')
        let pickskrest = document.querySelector('.pickskrest')
        let picksbruceInputs:any = picksbruce.querySelectorAll('input[type=checkbox]')
        let pickskolInputs:any = pickskol.querySelectorAll('input[type=checkbox]')
        let picksrelsInputs:any = picksrels.querySelectorAll('input[type=checkbox]')
        let pickskrestInputs:any = pickskrest.querySelectorAll('input[type=checkbox]')

        let firstPickArray=[]
        let bruceArray=[]
        let skolArray=[]
        let relsArray=[]
        let krestArray=[]
        let allArrays=[]

        Prods[0].data.forEach(e => {
            if (e.group === activeCatSwitchValue){
                tempList.push(e)
            }
        })
        console.log(tempList);
        let arrowRows = `<table class="finalTable">
            <tr>
                <th>Наименование</th>
                <th>Назначение</th>
                <th>Вид бруса</th>
                <th>Тип рельса</th>
                <th>Ширина колеи</th>
                <th>Марка крестовины</th>
                <th>Обозначение крестовины</th>
                <th>Обозначение рем. комплектов</th>
            </tr>
        </table>` 
        let crossRows = `<table class="finalTable">
        <tr>
            <th>Обозначение
            крестовины</th>
            <th>Проект стрелочного
            перевода</th>
            <th>Тип продукции</th>
            <th>Назначение</th>
            <th>Вид бруса</th>
            <th>Тип рельса</th>
            <th>Ширина колеи</th>
            <th>Марка крестовины</th>
        </tr>
    </table>`
        let remRows = `<table class="finalTable">
        <tr>
            <th>Обозначение
            ремонтного комплекта</th>
            <th>Проект стрелочного
            перевода</th>
            <th>Тип продукции</th>
            <th>Назначение</th>
            <th>Вид бруса</th>
            <th>Тип рельса</th>
            <th>Ширина колеи</th>
            <th>Марка крестовины</th>
        </tr>
    </table>`
    let prochRows = `<table class="finalTable">
    <tr>
        <th>Наименование</th>
        <th>Назначение</th>
        <th>Вид бруса</th>
        <th>Тип рельса</th>
        <th>Ширина колеи</th>
        <th>Применяемость</th>
    </tr>
</table>`
        tempList.forEach(e=>{
            firstPickInputs.forEach(v=>{
                if (v.checked){
                    if (e.type === v.value){
                        firstPickArray.push(e.id)
                    }
                }
            })
            picksbruceInputs.forEach(v=>{
                if (v.checked){
                    if (e.bar === v.value){
                        bruceArray.push(e.id)
                    }
                }
            })
            pickskolInputs.forEach(v=>{
                if (v.checked){
                    if (e.cross === v.value){
                        skolArray.push(e.id)
                    }
                }
            })
            picksrelsInputs.forEach(v=>{
                if (v.checked){
                    if (e.rail === v.value){
                        relsArray.push(e.id)
                    }
                }
            })
            pickskrestInputs.forEach(v=>{
                if (v.checked){
                    if (e.track === v.value){
                        krestArray.push(e.id)
                    }
                }
            })
        })
        if (firstPickArray.length>0){
            allArrays.push(firstPickArray)
        }
        if (bruceArray.length>0){
            allArrays.push(bruceArray)
        }
        if (skolArray.length>0){
            allArrays.push(skolArray)
        }
        if (relsArray.length>0){
            allArrays.push(relsArray)
        }
        if (krestArray.length>0){
            allArrays.push(krestArray)
        }
        if (allArrays.length<=0){
            finalList=tempList
            if(activeCatSwitchValue === "Стрелочный перевод "){
                resultTable.innerHTML = arrowRows
            var Table:HTMLTableElement=document.querySelector('.finalTable')
                addTables(1)
            } else if (activeCatSwitchValue === "Крестовины") {
                resultTable.innerHTML = crossRows
                var Table:HTMLTableElement=document.querySelector('.finalTable')
                addTables(2)
            } else if (activeCatSwitchValue === "Ремкомплекты") {
                resultTable.innerHTML = remRows
                var Table:HTMLTableElement=document.querySelector('.finalTable')
                addTables(3)
            } else if (activeCatSwitchValue === "Прочая"){
                resultTable.innerHTML = prochRows
                var Table:HTMLTableElement=document.querySelector('.finalTable')
                addTables(4)
            }
        } else {
            let idlist = allArrays.reduce((p, c) => 
            p.filter(e => c.includes(e)))
            console.log(idlist);
            tempList.forEach(e=>{
                idlist.forEach(m => {
                    if(e.id === m){
                        finalList.push(e);
                    }
                });
            })
            if(activeCatSwitchValue === "Стрелочный перевод "){
                resultTable.innerHTML = arrowRows
                var Table:HTMLTableElement=document.querySelector('.finalTable')
                addTables(1)
                
            } else if (activeCatSwitchValue === "Крестовины"){
                resultTable.innerHTML = crossRows
                var Table:HTMLTableElement=document.querySelector('.finalTable')
                addTables(2)
            } else if (activeCatSwitchValue === "Ремкомплекты"){
                resultTable.innerHTML = remRows
                var Table:HTMLTableElement=document.querySelector('.finalTable')
                addTables(3)
            } else if (activeCatSwitchValue === "Прочая"){
                resultTable.innerHTML = prochRows
                var Table:HTMLTableElement=document.querySelector('.finalTable')
                addTables(4)
            }
        }
        function addTables(num){
            let tablerow = 0
            if (num === 1){
                finalList.forEach(e=>{
                    let newRow = Table.insertRow(Table.rows.length)
                    let Cell1 = newRow.insertCell(0)
                    let Cell2 = newRow.insertCell(1)
                    let Cell3 = newRow.insertCell(2)
                    let Cell4 = newRow.insertCell(3)
                    let Cell5 = newRow.insertCell(4)
                    let Cell6 = newRow.insertCell(5)
                    let Cell7 = newRow.insertCell(6)
                    let Cell8 = newRow.insertCell(7)
                    tablerow++
                    if(tablerow%2===0){
                        newRow.style.backgroundColor='#ee964b36'
                    } else {
                        newRow.style.backgroundColor='#ffffff78'
                    }
                    
                    Cell1.innerHTML=`${e.name.replace(/\|/g, '<br/>').replace(/\s\s/g, '<br/>') + (e.img>0? `<br/><a target="_blank" href="../../assets/img/${e.img}.`+`${e.img>300?"pdf":"jpg"}`+`\">Узнать подробнее</a>`: ``) }`
                    Cell2.innerHTML=`${e.type.replace(/\|/g, '<br/>')}`
                    Cell3.innerHTML=`${e.bar.replace(/\|/g, '<br/>')}`
                    Cell4.innerHTML=`${e.rail.replace(/\|/g, '<br/>')}`
                    Cell5.innerHTML=`${e.cross.replace(/\|/g, '<br/>')}`
                    Cell6.innerHTML=`${e.track.replace(/\|/g, '<br/>')}`
                    Cell7.innerHTML=`${e.name_cross.replace(/\|/g, '<br/>')}`
                    Cell8.innerHTML=`${e.text.replace(/\|/g, '<br/>')}`
                    newRow.classList.add('tableRow')
                })

                
            } else if (num===2){
                finalList.forEach(e=>{
                    let newRow = Table.insertRow(Table.rows.length)
                    let Cell1 = newRow.insertCell(0)
                    let Cell2 = newRow.insertCell(1)
                    let Cell3 = newRow.insertCell(2)
                    let Cell4 = newRow.insertCell(3)
                    let Cell5 = newRow.insertCell(4)
                    let Cell6 = newRow.insertCell(5)
                    let Cell7 = newRow.insertCell(6)
                    let Cell8 = newRow.insertCell(7)
                    tablerow++
                    if(tablerow%2===0){
                        newRow.style.backgroundColor='#ee964b36'
                    } else {
                        newRow.style.backgroundColor='#ffffff78'
                    }
    
                    Cell1.innerHTML=`${e.name_cross.replace(/\|/g, '<br/>').replace(/\s\s/g, '<br/>') + (e.img>0? `<br/><a target="_blank" href="../../assets/img/${e.img}.`+`${e.img>300?"pdf":"jpg"}`+`\">Узнать подробнее</a>`: ``)}`
                    Cell2.innerHTML=`${e.name.replace(/\|/g, '<br/>')}`
                    Cell3.innerHTML=`${e.text.replace(/\|/g, '<br/>')}`
                    Cell4.innerHTML=`${e.type.replace(/\|/g, '<br/>')}`
                    Cell5.innerHTML=`${e.bar.replace(/\|/g, '<br/>')}`
                    Cell6.innerHTML=`${e.rail.replace(/\|/g, '<br/>')}`
                    Cell7.innerHTML=`${e.cross.replace(/\|/g, '<br/>')}`
                    Cell8.innerHTML=`${e.track.replace(/\|/g, '<br/>')}`
                    newRow.classList.add('tableRow')
                })
            } else if (num===3){
                finalList.forEach(e=>{
                    let newRow = Table.insertRow(Table.rows.length)
                    let Cell1 = newRow.insertCell(0)
                    let Cell2 = newRow.insertCell(1)
                    let Cell3 = newRow.insertCell(2)
                    let Cell4 = newRow.insertCell(3)
                    let Cell5 = newRow.insertCell(4)
                    let Cell6 = newRow.insertCell(5)
                    let Cell7 = newRow.insertCell(6)
                    let Cell8 = newRow.insertCell(7)
                    tablerow++
                    if(tablerow%2===0){
                        newRow.style.backgroundColor='#ee964b36'
                    } else {
                        newRow.style.backgroundColor='#ffffff78'
                    }
    
                    Cell1.innerHTML=`${e.name_cross.replace(/\|/g, '<br/>').replace(/\s\s/g, '<br/>') + (e.img>0? `<br/><a target="_blank" href="../../assets/img/${e.img}.`+`${e.img>300?"pdf":"jpg"}`+`\">Узнать подробнее</a>`: ``)}`
                    Cell2.innerHTML=`${e.name.replace(/\|/g, '<br/>')}`
                    Cell3.innerHTML=`${e.text.replace(/\|/g, '<br/>')}`
                    Cell4.innerHTML=`${e.type.replace(/\|/g, '<br/>')}`
                    Cell5.innerHTML=`${e.bar.replace(/\|/g, '<br/>')}`
                    Cell6.innerHTML=`${e.rail.replace(/\|/g, '<br/>')}`
                    Cell7.innerHTML=`${e.cross.replace(/\|/g, '<br/>')}`
                    Cell8.innerHTML=`${e.track.replace(/\|/g, '<br/>')}`
                    newRow.classList.add('tableRow')
                })
            } else if (num===4){
                finalList.forEach(e=>{
                    let newRow = Table.insertRow(Table.rows.length)
                    let Cell1 = newRow.insertCell(0)
                    let Cell2 = newRow.insertCell(1)
                    let Cell3 = newRow.insertCell(2)
                    let Cell4 = newRow.insertCell(3)
                    let Cell5 = newRow.insertCell(4)
                    let Cell6 = newRow.insertCell(5)
                    tablerow++
                    if(tablerow%2===0){
                        newRow.style.backgroundColor='#ee964b36'
                    } else {
                        newRow.style.backgroundColor='#ffffff78'
                    }
    
                    Cell1.innerHTML=`${e.name.replace(/\|/g, '<br/>').replace(/\s\s/g, '<br/>')+ (e.img>0? `<br/><a target="_blank" href="../../assets/img/${e.img}.`+`${e.img>300?"pdf":"jpg"}`+`\">Узнать подробнее</a>`: ``)}`
                    Cell2.innerHTML=`${e.name_cross.replace(/\|/g, '<br/>')}`
                    Cell3.innerHTML=`${e.bar.replace(/\|/g, '<br/>')}`
                    Cell4.innerHTML=`${e.rail.replace(/\|/g, '<br/>')}`
                    Cell5.innerHTML=`${e.cross.replace(/\|/g, '<br/>')}`
                    Cell6.innerHTML=`${e.text.replace(/\|/g, '<br/>')}`
                    newRow.classList.add('tableRow')
                })
            }
            Table.classList.add('resultTable')
        }
    }
  }
}
