<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Hello</title>
</head>
<body class="body">
  <div class="background"></div>
  <div class="container">
    <app-mobiletopnav class="popupmenu"></app-mobiletopnav>
    <app-topnav></app-topnav>
    <router-outlet></router-outlet>
    <app-bottom></app-bottom>
  </div>

</body>
</html>