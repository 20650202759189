import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confpolicy',
  templateUrl: './confpolicy.component.html',
  styleUrls: ['./confpolicy.component.sass']
})
export class ConfpolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
 }
