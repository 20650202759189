<div class="topnav-container">
    <div class="topnav-bar">
        <!-- <div class="topnav-bar-menu"> -->
            <ul class="mainbar">
                <li><a href="mailto:tdnszinfo@tdnsz.ru" class="topMail">tdnszinfo@tdnsz.ru</a></li>
                <li><a routerLink="/" routerLinkActive="activeTopMenu" [routerLinkActiveOptions]="{exact: true}">ГЛАВНАЯ</a></li>
                <li><a routerLink="/about" routerLinkActive="activeTopMenu">О&nbsp;КОМПАНИИ</a></li>
                <li><a routerLink="/catalogue/arrow" routerLinkActive="activeTopMenu">КАТАЛОГ</a></li>
                <li><a routerLink="/contacts" routerLinkActive="activeTopMenu">КОНТАКТЫ</a></li>
            </ul>
        <!-- </div> -->
        <img src="../../assets/nsz_logo.svg" alt="" class="logo">
        <!-- <div class="topnav-bar-infobar"> -->
            <ul class="infobar">
                <!-- <li><img src="../../assets/phone.svg" alt=""></li> -->
                <li><a href="tel:+74951234756">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                        <path d="M19.4755 14.678L16.6786 11.8869C15.6797 10.8901 13.9816 11.2889 13.582 12.5847C13.2823 13.4818 12.2834 13.9802 11.3844 13.7808C9.38661 13.2824 6.68957 10.6907 6.19012 8.59745C5.89045 7.70029 6.48979 6.70348 7.3888 6.40448C8.68738 6.00575 9.08694 4.31119 8.08804 3.31438L5.2911 0.523323C4.49198 -0.174441 3.29329 -0.174441 2.59406 0.523323L0.696143 2.41725C-1.20177 4.41087 0.895924 9.69394 5.59077 14.3789C10.2856 19.0639 15.5798 21.2569 17.5776 19.2633L19.4755 17.3693C20.1748 16.5719 20.1748 15.3757 19.4755 14.678Z" fill="white"/>
                        </g>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="mailto:tdnszinfo@tdnsz.ru">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                        <path d="M18.2422 2.38281H1.75781C0.789766 2.38281 0 3.17172 0 4.14062V15.8594C0 16.825 0.78625 17.6172 1.75781 17.6172H18.2422C19.2078 17.6172 20 16.8309 20 15.8594V4.14062C20 3.175 19.2138 2.38281 18.2422 2.38281ZM17.9995 3.55469L10.0373 11.5169L2.00621 3.55469H17.9995ZM1.17188 15.6167V4.3777L6.81559 9.97301L1.17188 15.6167ZM2.00051 16.4453L7.64777 10.798L9.62656 12.7598C9.85563 12.987 10.2253 12.9862 10.4534 12.758L12.3828 10.8286L17.9995 16.4453H2.00051ZM18.8281 15.6167L13.2114 10L18.8281 4.38328V15.6167Z" fill="white"/>
                        </g>
                        </svg>
                    </a>
                </li>
                <li><a routerLink="/contacts">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <!-- <g opacity="0.8"> -->
                        <path d="M10 0C6.00607 0 2.75677 3.2493 2.75677 7.2432C2.75677 12.1998 9.23877 19.4763 9.51474 19.7836C9.77396 20.0723 10.2265 20.0718 10.4853 19.7836C10.7613 19.4763 17.2433 12.1998 17.2433 7.2432C17.2432 3.2493 13.9939 0 10 0ZM10 10.8875C7.99056 10.8875 6.3558 9.25266 6.3558 7.2432C6.3558 5.23375 7.9906 3.59898 10 3.59898C12.0094 3.59898 13.6442 5.23379 13.6442 7.24324C13.6442 9.2527 12.0094 10.8875 10 10.8875Z" fill="white" fill-opacity="0.8"/>
                        <!-- </g> -->
                        </svg>
                    </a>
                </li>
            </ul>
        <div class="burgerholder">
            <div class="burger burgerinactive"></div>
        </div>
        <!-- </div> -->
        <div class="line"></div>
    </div>
</div>   