import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobiletopnav',
  templateUrl: './mobiletopnav.component.html',
  styleUrls: ['./mobiletopnav.component.sass']
})
export class MobiletopnavComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {

    var body = document.querySelector('body');
    var burgerHolder = document.querySelector('.burgerholder');
    var mobileBurger = document.querySelector('.burgerinactive');
    var mobileNav = document.querySelector('.popupmenu')
    
    burgerHolder.addEventListener('click', () => {
        if(mobileBurger.classList.contains('burgercross')){
            mobileBurger.classList.remove('burgercross');
            mobileNav.classList.remove('popupmenuactive');
            body.classList.remove('stop-scrolling');
        } else {
            mobileNav.classList.add('popupmenuactive');
            mobileBurger.classList.add('burgercross');
            body.classList.add('stop-scrolling');
            
        }
    })
  }
  
  onHomeClick() {
    var body = document.querySelector('body');
    var mobileBurger = document.querySelector('.burgerinactive');
    var mobileNav = document.querySelector('.popupmenu')
    mobileBurger.classList.remove('burgercross');
    mobileNav.classList.remove('popupmenuactive');
    body.classList.remove('stop-scrolling');
    this.router.navigate(['/']);
  }
  onAboutClick() {
    var body = document.querySelector('body');
    var mobileBurger = document.querySelector('.burgerinactive');
    var mobileNav = document.querySelector('.popupmenu')
    mobileBurger.classList.remove('burgercross');
    mobileNav.classList.remove('popupmenuactive');
    body.classList.remove('stop-scrolling');
    this.router.navigate(['/about']);
  }
  onCatalogueClick() {
    var body = document.querySelector('body');
    var mobileBurger = document.querySelector('.burgerinactive');
    var mobileNav = document.querySelector('.popupmenu')
    mobileBurger.classList.remove('burgercross');
    mobileNav.classList.remove('popupmenuactive');
    body.classList.remove('stop-scrolling');
    this.router.navigate(['/catalogue/']);
  }
  onContactClick() {
    var body = document.querySelector('body');
    var mobileBurger = document.querySelector('.burgerinactive');
    var mobileNav = document.querySelector('.popupmenu')
    mobileBurger.classList.remove('burgercross');
    mobileNav.classList.remove('popupmenuactive');
    body.classList.remove('stop-scrolling');
    this.router.navigate(['/contacts']);
  }
}
