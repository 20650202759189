import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent implements OnInit {

  constructor() {
   }

  ngOnInit(): void {
  }
  onContainerClick(){
    let certHolder: HTMLDivElement = document.querySelector('.certContainer')
    certHolder.classList.remove('certHolderActive')
  }
  
  onCertClick(e){
    let imgtag = `
    <img class="moduleImg" height="100%" src="/assets/certs/jpgs/${e}.jpg">
    `
    let certHolder: HTMLDivElement = document.querySelector('.certContainer')
    let module: HTMLDivElement = document.querySelector('.certModule')
    module.innerHTML=imgtag
    certHolder.classList.add('certHolderActive')
    module.classList.add('certModuleActive')
  }
}
