<div class="container section">
    <h1 class="title">Контакты</h1>
    <div class="mainsection">
        <div class="mainsection_top">
            <div class="left">
                <div class="contleft contadr">
                    <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 10.8648C1 5.43006 5.44223 1 10.9091 1C16.3759 1 20.8181 5.43005 20.8182 10.8648C20.8182 12.5237 20.2682 14.4521 19.3723 16.4699C18.4819 18.4753 17.2818 20.4987 16.0545 22.3282C13.6415 25.9252 11.176 28.6958 10.9091 28.9927C10.6422 28.6958 8.17663 25.9252 5.76369 22.3282C4.5364 20.4987 3.33623 18.4753 2.44586 16.4699C1.54995 14.4521 1 12.5237 1 10.8648ZM10.921 29.0059L10.9209 29.0059C10.921 29.0059 10.921 29.0059 10.921 29.0059ZM4.42051 10.8648C4.42051 14.4351 7.33417 17.3312 10.9091 17.3312C14.4839 17.3312 17.3976 14.4352 17.3976 10.8649C17.3976 7.29461 14.484 4.39848 10.9091 4.39848C7.33425 4.39848 4.42051 7.29452 4.42051 10.8648Z" stroke="white" stroke-opacity="0.8" stroke-width="2"/>
                    </svg>
                        
                    <p>125438, г. Москва, вн.тер.г. муниципальный округ Головинский, ул. Автомоторная, д. 1/3, стр.2, этаж 2, пом. 1, ком. 15, офис 210</p>
                </div>
                <div class="contleft conttel">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.507 22.7248L28.507 22.7248C29.1346 23.3511 29.2023 24.5271 28.4813 25.3718L25.6601 28.1871L26.3664 28.8949L25.6601 28.1871C25.1495 28.6965 24.3762 28.998 23.2813 29C22.1782 29.002 20.8181 28.696 19.2724 28.063C16.1844 26.7983 12.5458 24.3066 9.09253 20.8605C5.64245 17.4177 3.18279 13.7886 1.93347 10.7075C1.30815 9.16528 1.00411 7.80482 1.00004 6.69906C0.996014 5.60382 1.28305 4.83055 1.76049 4.32384L4.59746 1.49283C5.22633 0.86528 6.40823 0.79788 7.256 1.51849L11.4257 5.67942C12.4223 6.67395 11.9734 8.2873 10.7897 8.65076L10.7786 8.65416L10.7676 8.65782C8.99881 9.24611 7.70256 11.2266 8.32242 13.1693C8.76867 14.9846 10.1035 16.883 11.6362 18.3932C13.1814 19.9156 15.0942 21.2074 16.8346 21.6415L16.8473 21.6447L16.8601 21.6475C18.6594 22.0466 20.6943 21.0715 21.3215 19.1938L21.3252 19.1828L21.3286 19.1717C21.6919 17.9936 23.3115 17.5402 24.3116 18.5382L28.507 22.7248Z" stroke="white" stroke-opacity="0.8" stroke-width="2"/>
                    </svg>
                        
                    <p><a href="tel:+74951234756">+7 (495) 123-47-56</a></p>
                </div>
                <div class="contleft contnsz">
                    <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.9359 0H2.88462C1.29603 0 0 1.29462 0 2.88462V22.1154C0 23.7 1.29026 25 2.88462 25H29.9359C31.5205 25 32.8205 23.7097 32.8205 22.1154V2.88462C32.8205 1.3 31.5303 0 29.9359 0ZM29.5376 1.92308L16.4714 14.9893L3.29225 1.92308H29.5376ZM1.92308 21.7172V3.27365L11.1846 12.4557L1.92308 21.7172ZM3.28289 23.0769L12.5502 13.8096L15.7975 17.029C16.1733 17.4017 16.78 17.4004 17.1543 17.026L20.3205 13.8598L29.5377 23.0769H3.28289ZM30.8975 21.7171L21.6803 12.5L30.8975 3.28282V21.7171Z" fill="white" fill-opacity="0.8"/>
                    </svg>
                    <p><a href="mailto:tdnszinfo@tdnsz.ru">tdnszinfo@tdnsz.ru</a></p>
                </div>
            </div>
            <div class="right">
                <form action="">
                    <input type="name" id="name" placeholder="ВАШЕ ИМЯ">

                    <input type="email" id="email" placeholder="ВАША ПОЧТА">

                    <textarea name="message" id="message" placeholder="ВАШЕ СООБЩЕНИЕ"></textarea>
                    <button type="submit">
                        Отправить
                    </button>
                </form>
            </div>

    </div>
    <button class="mainbutton" routerLink="/catalogue/arrow">В каталог</button>
</div>