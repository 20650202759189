<div class="container section">
    <h1 class="title">Стрелочная продукция<br/>
        для железных дорог</h1>
    <div class="mainsection">
        <div class="mainsection_top">
            <p class="left">Основной вид деятельности компании — оптовая торговля стрелочными переводами и комплектующими. ООО «ТД НСЗ» является эксклюзивным дилером Акционерного общества «Новосибирский стрелочный завод». </p>
            <p class="right">Изделия крупнейшего в РФ производителя ЖД продукции (каталог Новосибирского стрелочного завода включает в себя более трех сотен наименований) используется в строительстве и ремонте магистральных железных дорог, в обустройстве подъездных путей к предприятиям, в трамвайном хозяйстве и метрополитенах.</p>
        </div>
        <button class="mainbutton" routerLink="catalogue/arrow">Посмотреть продукцию</button>
    </div>
</div>