<div class="switchContainer">
    <div class="firstPick">
        <div>
            <input type="checkbox" id="1" name="1" value="Уравнительный прибор">
            <label for="1">Уравнительный прибор</label>
        </div>
        <div>
            <input type="checkbox" id="2" name="2" value="Стрелка сбрасывающая">
            <label for="2">Стрелка сбрасывающая</label>
        </div>
        <div>
            <input type="checkbox" id="3" name="3" value="Ручной переводной механизм">
            <label for="3">Ручной переводной механизм</label>
        </div>
        <div>
            <input type="checkbox" id="4" name="4" value="Башмакосбрасыватели">
            <label for="4">Башмакосбрасыватели</label>
        </div>
        <div>
            <input type="checkbox" id="5" name="5" value="Гарнитура">
            <label for="5">Гарнитура</label>
        </div>
        <!-- <div>
            <input type="checkbox" id="6" name="6" value="Продукция литейного производства">
            <label for="6">Продукция литейного производства</label>
        </div> -->
        <div>
            <input type="checkbox" id="7" name="7" value="Комплекты электрообогрева">
            <label for="7">Комплекты электрообогрева</label>
        </div>    
    </div>
</div>
