<div class="container section">
  <h1 class="title">О компании</h1>
  <div class="mainsection aboutsection">
    <div class="mainsection_top">
      <div class="left aboutLeft">
        <svg width="200" height="200" viewBox="0 0 94 93" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M64.0047 3.01942C63.9212 18.2303 63.8414 33.4463 63.7654 48.6673C64.2326 49.1073 64.7036 49.5739 65.1784 50.0746C66.4448 51.4129 67.6055 52.8473 68.65 54.3648V11.2052C68.65 11.2052 89.1946 22.4636 89.0769 47.2752C88.9591 72.0868 68.2663 82.3968 68.2663 82.3968V61.6781C68.2663 61.6781 68.4183 57.4714 65.5886 54.2358C62.7589 51.0001 54.7674 43.0495 54.7674 43.0495L45.3288 33.5664C45.3288 33.5664 41.5648 29.3673 41.5306 26.9055C41.4964 24.4437 41.5078 0 41.5078 0C41.5078 0 39.8898 0.0910404 38.8718 0.269323C38.1122 0.405879 36.7182 0.788992 36.3612 0.857271V26.6513C36.3612 26.6513 36.1029 30.9832 40.6342 35.5085C45.1655 40.0339 60.7952 55.5445 61.2548 56.1324C61.7144 56.7204 63.3514 58.029 63.4806 61.496C63.6097 64.9631 63.6135 89.566 63.6135 89.566C63.6135 89.566 94.1589 78.6908 93.9994 47.2979C94.0905 16.0151 67.8182 4.84397 64.0047 3.01942Z"
            fill="black" />
          <path
            d="M58.5283 1.07039V42.566L53.2076 37.3162V0C53.6641 0.0302584 55.4169 0.313933 55.9766 0.416056C56.8472 0.541341 57.7025 0.760667 58.5283 1.07039Z"
            fill="black" />
          <path
            d="M29.9971 89.6829C30.0807 74.4861 30.1567 59.1831 30.2402 43.9825C29.7731 43.5428 29.3021 43.0765 28.8312 42.5762C27.5625 41.2407 26.4018 39.8072 25.3598 38.2889V81.4192C25.3598 81.4192 4.80888 70.1609 4.93041 45.3737C5.05195 20.5865 25.7396 10.2683 25.7396 10.2683V30.9729C25.7396 30.9729 25.5877 35.1768 28.4172 38.4102C31.2467 41.6437 39.2376 49.5889 39.2376 49.5889L48.6757 59.0656C48.6757 59.0656 52.4395 63.2619 52.4736 65.722C52.5078 68.1822 52.4736 92.2264 52.4736 92.2264C53.338 92.1857 54.1992 92.0933 55.0525 91.9497C55.8121 91.8284 57.054 91.5706 57.6389 91.3963V65.9912C57.6389 65.9912 57.8934 61.6622 53.3624 57.1399C48.8314 52.6177 33.2026 37.1176 32.7431 36.53C32.2835 35.9425 30.6466 34.6347 30.5175 31.17C30.3883 27.7054 30.3845 2.66037 30.3845 2.66037C30.3845 2.66037 -0.158893 13.9983 0.000622644 45.3699C0.160138 76.7415 27.7753 88.6821 29.9971 89.6829Z"
            fill="black" />
          <path
            d="M36.3585 91.2525V49.6604L41.6792 54.9V92.2264C41.219 92.1622 39.2637 91.8753 38.7703 91.7772C37.3232 91.4903 37.3232 91.4903 36.3585 91.2525Z"
            fill="black" />
        </svg>
        <p><span> Общество с ограниченной ответственностью «Торговый дом  
          Новосибирского стрелочного завода»</span></p>
      </div>
      <div class="right aboutRight">
        <p>основано в 2020 году. Основной вид деятельности компании — оптовая торговля стрелочными переводами и комплектующими. ООО «ТД НСЗ» является эксклюзивным дилером Акционерного общества «Новосибирский стрелочный завод».</p>

          <p>АО «НСЗ» изготавливает высококачественную продукцию для строительства и ремонта магистральных железных дорог, в обустройстве подъездных путей к предприятиям, металлургическим и горно-обогатительным комбинатам, угольным разрезам, шахтам, в метрополитенах. </p>
      </div>
    </div>
  </div>
  <div class="certdiv">
    <h1>Сертификаты</h1>
    <div class="certs">
      <img src="/assets/certs/jpgs/1.jpg" alt="" class="cert" (click)="onCertClick(1)">
      <img src="/assets/certs/jpgs/2.jpg" alt="" class="cert" (click)="onCertClick(2)">
      <img src="/assets/certs/jpgs/3.jpg" alt="" class="cert" (click)="onCertClick(3)">
      <img src="/assets/certs/jpgs/4.jpg" alt="" class="cert" (click)="onCertClick(4)">
      <img src="/assets/certs/jpgs/5.jpg" alt="" class="cert" (click)="onCertClick(5)">
      <img src="/assets/certs/jpgs/6.jpg" alt="" class="cert" (click)="onCertClick(6)">
    </div>
    <div class="certContainer" (click)="onContainerClick()">
      <div class="certModule">
    </div>
  </div>
    
  </div>
</div>
